import React from "react";
import FromToPage from "../../components/FromToPage";
import { useDispatch, useSelector } from "react-redux";
import langData from "../../lang/data";
import { useEffect } from "react";
import ProductsWithSlide from "../../components/ProductsWithSlide";
import CategoryBlock from "../Hisense/categoryBlock";
import { getBrandPageInfo } from "../../store/MainSlice";
import styles from "./style.module.css";
import LoadContent from "../../components/LoadContent";

const pages = [{ label: "toshiba", value: "/toshiba", active: true }];

const Toshiba = () => {
  const dispatch = useDispatch();
  const { currentLanguage, brand } = useSelector(({ main }) => main);
  useEffect(() => {
    dispatch(getBrandPageInfo("toshiba"));
  }, []);

  return (
    <>
      <div className="wrapper">
        <FromToPage pages={pages} />
        <a href={brand?.banners?.banner_1_href}>
          {!brand ? (
            <LoadContent containerClassName={styles.LoadContentGeneralBanner} />
          ) : (
            <div className={styles.generalBanner}>
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand.banners["banner_1_" + currentLanguage]
                }
              />
            </div>
          )}
        </a>

        <div className={styles.twoBanersBlck}>
          <a href={brand?.banners?.banner_2_href}>
            {!brand ? (
              <LoadContent
                containerClassName={styles.LoadContentTwoBanersBlck}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_2_" + currentLanguage]
                }
              />
            )}
          </a>
          <a href={brand?.banners?.banner_3_href}>
            {!brand ? (
              <LoadContent
                containerClassName={styles.LoadContentTwoBanersBlck}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_3_" + currentLanguage]
                }
              />
            )}
          </a>
        </div>

        <div className={styles.infoAboutBrand}>
          <element
            dangerouslySetInnerHTML={{
              __html: brand?.info?.["text_1_" + currentLanguage],
            }}
          ></element>
        </div>
      </div>
      <div className={styles.topPicksProduct}>
        <div className="wrapper">
          <div className={styles.titleBlck}>
            <h1>{langData.titles.BestDealProduct[currentLanguage]}</h1>
          </div>
        </div>
        <ProductsWithSlide productsData={brand?.products_first_slider} />
      </div>
      <div className="wrapper">
        <div className={styles.bigBanner}>
          <a href={brand?.banners?.banner_4_href}>
            <div className={styles.generalBanner}>
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_4_" + currentLanguage]
                }
              />
            </div>
          </a>
          <a href={brand?.banners?.banner_5_href}>
            <div className={styles.generalBanner}>
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_5_" + currentLanguage]
                }
              />
            </div>
          </a>
        </div>
        <CategoryBlock
          myData={brand?.products_second_slider}
          showMoreBtn="true"
          url={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/brand/toshiba/category`}
        />
        <div className={styles.lastInfoBrand}>
          <element
            dangerouslySetInnerHTML={{
              __html: brand?.info?.["text_2_" + currentLanguage],
            }}
          ></element>
        </div>
      </div>
    </>
  );
};

export default Toshiba;
