import React from "react";

const CardBackground = ({ width }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 159 252"
      fill="none"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4.99999C0 2.23857 2.23858 0 5 0H153.978C156.748 0 158.99 2.2522 158.978 5.02232L158.022 219.022C158.01 221.775 155.775 224 153.022 224H135.343C132.582 224 130.343 226.239 130.343 229V246.625C130.343 249.386 128.104 251.625 125.343 251.625H4.99999C2.23857 251.625 0 249.386 0 246.625V4.99999Z"
        fill="white"
      />
      <path
        d="M1 4.99999C1 2.79086 2.79086 1 5 1H153.978C156.194 1 157.988 2.8017 157.978 5.01799L157.022 219.018C157.012 221.22 155.224 223 153.022 223H135.343C132.03 223 129.343 225.687 129.343 229V246.625C129.343 248.834 127.552 250.625 125.343 250.625H4.99999C2.79091 250.625 1 248.834 1 246.625V4.99999Z"
        stroke="#939393"
        strokeOpacity="0.3"
        strokeWidth="1"
      />
    </svg>
  );
};

export default CardBackground;
