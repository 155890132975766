import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import UseCalcPrice from "../../helpers/UseCalcPrice";
import { useDispatch, useSelector } from "react-redux";
import PaymentType from "./components/PaymentType";
import UseOutSideClick from "../../helpers/UseOutSideClick";
import langData from "../../lang/data";
import { addCardStore, postCreditModal } from "../../store/MainSlice";
import data from "./data";
import Agreement from "./components/Agreemnet";
import ThanksModal from "./components/ThanksModal";
import axiosInstance from "../../store/request";
import CircleLoader from "../CircleLoader";
import lang from "../../lang/lang";

export default function CreditModal({
  modalType,
  closeModal,
  product,
  qty,
  totalPrice,
  thanksModalProps,
  agreementInfoProps,
  installingIsActive,
  haveInstalling,
  propsPrice,
  choosenGift,
}) {
  const [activePaymentType, setActivePaymentType] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [flat, setFlat] = useState("");
  const [comment, setComment] = useState("");
  const [agreementInfo, setAgreementInfo] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);
  const [submitFormTag, setSubmitFormTag] = useState(null);
  const [pending, setPending] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { currentCurrency, currentLanguage } = useSelector(({ main }) => main);
  let price =
    propsPrice ||
    (totalPrice ||
      (product?.online_price && product?.online_selling_price) ||
      product?.skus?.[0]?.recommended_retail_price ||
      product?.skus?.[0].promo_price ||
      product?.skus?.[0].selling_price) -
      ((!installingIsActive && haveInstalling) || 0);

  const mainRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (modalType != "cart")
      if (modalType && product?.seller_product_sku_id) {
        dispatch(
          addCardStore({
            price,
            qty: qty || 1,
            product_id: product?.seller_product_sku_id, // ?
            seller_id: 1,
            shipping_method_id: 0,
            type: "product",
            is_buy_now: "yes",
            // gift_product_id: choosenGift,
          })
        );

        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
  }, [modalType]);

  useEffect(() => {
    const formSubmit = document.querySelector("#formSubmit form");

    if (formSubmit?.submit) {
      formSubmit.submit();
      setSubmitFormTag(null);
    }
  }, [submitFormTag]);
  UseOutSideClick(mainRef, () => {
    if (!agreementInfo && !thanksModal) closeModal();
  });

  const reqestFormTag = (formTag) => {
    setPending(false);
    setSubmitFormTag(formTag?.data);
    return null;
  };
  useEffect(() => {
    if (thanksModalProps) setThanksModal(thanksModalProps);
  }, [thanksModalProps]);
  useEffect(() => {
    if (agreementInfoProps) setAgreementInfo(agreementInfoProps);
  }, [agreementInfoProps]);

  if (!modalType) return null;
  return (
    <div className={styles.background}>
      <div ref={mainRef}>
        {pending ? (
          <div className={styles.loaderMain}>
            <CircleLoader containerStyle={{ height: "100%" }} />
          </div>
        ) : thanksModal ? (
          <ThanksModal
            data={{
              ...agreementInfo,
              name: firstName,
              surname: lastName,
              email: email,
              phone: phone,
              flat: flat,
              address_inp: address,
              note: comment,
            }}
            qty={qty}
            product={product}
            close={() => {
              setAgreementInfo(null);
              setThanksModal(null);
              closeModal();
            }}
          />
        ) : agreementInfo ? (
          <Agreement
            setPending={setPending}
            fullName={firstName + " " + lastName}
            agreementInfo={agreementInfo}
            back={() => setAgreementInfo(null)}
            close={() => {
              setAgreementInfo(null);
              setThanksModal(null);
              closeModal();
            }}
            setThanksModal={() => setThanksModal(true)}
          />
        ) : (
          <div className={styles.main}>
            <>
              <div className={styles.header}>
                <span className={styles.title}>
                  {data?.[modalType]?.title[currentLanguage]}
                </span>
                <span className={styles.closeBtn} onClick={closeModal}>
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
                  </svg>
                </span>
              </div>
              <div className={styles.twoInputs}>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className={styles.input}
                  placeholder={
                    langData.loginModal.FirstName[currentLanguage] + "*"
                  }
                  style={
                    firstNameError
                      ? {
                          borderColor: "#d10000",
                        }
                      : null
                  }
                />
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className={styles.input}
                  placeholder={langData.loginModal.LastName[currentLanguage]}
                />
              </div>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
                style={
                  emailError
                    ? {
                        borderColor: "#d10000",
                      }
                    : null
                }
                placeholder={langData.loginModal.email[currentLanguage] + "*"}
              />
              <input
                type="text"
                value={phone[0] ? (phone[0] === "0" ? "" : "+") + phone.replace(/\D/g, "") : phone} 
                onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))} 
             
                className={styles.input}
                style={
                  phoneError
                    ? {
                        borderColor: "#d10000",
                      }
                    : null
                }
                placeholder="Բջջային հեռ. *"
              />
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={styles.input}
                style={
                  addressError
                    ? {
                        borderColor: "#d10000",
                      }
                    : null
                }
                placeholder={data.lang.address[currentLanguage]}
              />
              <input
                type="text"
                value={flat}
                onChange={(e) => setFlat(e.target.value)}
                className={styles.input}
                placeholder={data.lang.flat[currentLanguage]}
              />
              {/* <div className={styles.priceBlock}>
              <span className={styles.priceText}>
                {data.lang.delivary[currentLanguage]}
              </span>
              <span className={styles.price}></span>
            </div> */}
              <div className={styles.priceBlock}>
                <span className={styles.priceText}>
                  {data.lang.total[currentLanguage]} ({qty})
                </span>
                <span className={styles.price}>
                  {UseCalcPrice(qty * price, currentCurrency)}
                </span>
              </div>
              <textarea
                name="coment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className={styles.input}
                style={{ minHeight: "100px", resize: "none" }}
                cols="30"
                rows="5"
                placeholder={data.lang.textarea[currentLanguage]}
                contentEditable={false}
              ></textarea>
              <span className={styles.paymentTitle}>
                {data?.[modalType].paymentTitle[currentLanguage]}
              </span>
              <div className={styles.paymentsBlock}>
                {data?.[modalType].paymentTypes?.map((item, index) => {
                  if (modalType !== "buyNow") {
                    if (price < 26000 && index === 3) {
                      return null;
                    }
                    if (
                      price < 30000 &&
                      (index === 0 || index === 1 || index === 2)
                    ) {
                      return null;
                    }
                    if (price < 100000 && index === 4) {
                      return null;
                    }
                  }

                  return (
                    <PaymentType
                      key={index}
                      imgs={item?.imgs}
                      active={activePaymentType === index}
                      setActive={() => setActivePaymentType(index)}
                      title={item?.title?.[currentLanguage]}
                    />
                  );
                })}
              </div>
              {errorMessage && (
                <span className={styles.errorMessage}>{errorMessage}</span>
              )}
              <div
                className={styles.buyBtn}
                onClick={() => {
                  setFirstNameError(!firstName);
                  setEmailError(!email);
                  setAddressError(!address);
                  setPhoneError(!phone);

                  if (
                    firstName &&
                    email &&
                    phone &&
                    address &&
                    typeof activePaymentType == "number"
                  ) {
                    setPending(true);
                    const sendData = {
                      name: firstName,
                      surname: lastName,
                      email: email,
                      phone: phone,
                      flat: flat,
                      address_inp: address,
                      note: comment,
                      number_of_item: qty || 1,
                      checkout_type: "buy_it_now",
                      number_of_package: 1,
                      discount_total: +(
                        ((product?.online_price &&
                          product?.online_selling_price) ||
                          product?.skus?.[0]?.recommended_retail_price ||
                          product?.skus?.[0].promo_price) -
                        ((!installingIsActive && haveInstalling) || 0)
                      ),
                      discount: +(
                        ((product?.online_price &&
                          product?.online_selling_price) ||
                          product?.skus?.[0]?.recommended_retail_price ||
                          product?.skus?.[0].promo_price) -
                        ((!installingIsActive && haveInstalling) || 0)
                      ),
                      grand_total: qty * +price,
                      sub_total: qty * +price,
                    };
                    if (modalType === "credit") {
                      sendData.credit = [
                        "Vtb",
                        "ineco",
                        "acba",
                      ]?.[activePaymentType];
                      sendData.gateway_id = 18;
                      sendData.payment_id = 18;
                    } else {
                      sendData.gateway_id =
                        data.buyNow.paymentTypes[activePaymentType].id;
                      sendData.payment_id =
                        data.buyNow.paymentTypes[activePaymentType].id;
                    }
                    dispatch(
                      postCreditModal(sendData, (e) => {
                        if (e?.data?.order?.id) {
                          if (
                            modalType === "credit" &&
                            data.credit.paymentTypes?.[activePaymentType]
                              .name === "vtb"
                          ) {
                            window.location.href =
                              "https://www.vtb.am/am/register/";
                          } else if (
                            modalType === "buyNow" &&
                            data.buyNow?.paymentTypes?.[activePaymentType]
                              .id === 1
                          ) {
                            setPending(false);
                            setAgreementInfo({
                              order_id: e.data.order.id,
                              order_number: e.data.order.order_number,
                            });
                            setThanksModal(true);
                          } else if (
                            modalType === "buyNow" &&
                            data.buyNow?.paymentTypes?.[activePaymentType]
                              .id === 16
                          ) {
                            axiosInstance
                              .get(
                                process.env.REACT_APP_SERVER_URL + "tellcell",
                                {
                                  params: {
                                    id: e.data.order.id,
                                    total: +price,
                                  },
                                }
                              )
                              .then(reqestFormTag)
                              // .then(({ data }) => {
                              //   axios.post(data.link, {
                              //     issuer: data.issuer,
                              //     action: data.action,
                              //     currency: data.currency,
                              //     price: data.price,
                              //     product: data.product,
                              //     issuer_id: data.issuer_id,
                              //     valid_days: data.valid_days,
                              //     lang: data.lang,
                              //     security_code: data.security_code,
                              //   });
                              // })
                              .catch(() => setPending(false));
                          } else if (
                            modalType === "buyNow" &&
                            data.buyNow?.paymentTypes?.[activePaymentType]
                              .id === 15
                          ) {
                            axiosInstance
                              .get(process.env.REACT_APP_SERVER_URL + "idram", {
                                params: { id: e.data.order.id, total: +price },
                              })
                              .then(reqestFormTag)
                              // .then(({ data }) => {
                              //   axios.post(data.link, {
                              //     EDP_LANGUAGE: data.EDP_LANGUAGE,
                              //     EDP_REC_ACCOUNT: data.EDP_REC_ACCOUNT,
                              //     EDP_DESCRIPTION: data.EDP_DESCRIPTION,
                              //     EDP_AMOUNT: data.EDP_AMOUNT,
                              //     EDP_BILL_NO: data.EDP_BILL_NO,
                              //     SECRET_KEY: data.SECRET_KEY,
                              //     SUCCESS_URL: data.SUCCESS_URL,
                              //     FAIL_URL: data.FAIL_URL,
                              //   });
                              // })
                              .catch(() => setPending(false));
                          } else if (
                            modalType === "buyNow" &&
                            data.buyNow?.paymentTypes?.[activePaymentType]
                              .id === 17
                          ) {
                            axiosInstance
                              .get(
                                process.env.REACT_APP_SERVER_URL +
                                  "arca/paymant/5",
                                {
                                  params: {
                                    id: e.data.order.order_number,
                                    total: +price,
                                  },
                                }
                              )
                              .then((e) => {
                                if (e.data.fail) {
                                  setPending(false);
                                  setErrorMessage(
                                    lang[currentLanguage].error_message
                                  );
                                } else {
                                  setPending(false);
                                  setSubmitFormTag(e.data?.form);
                                }
                              });
                          } else if (
                            modalType === "buyNow" &&
                            data.buyNow?.paymentTypes?.[activePaymentType]
                              .id === 19
                          ) {
                            axiosInstance
                              .get(
                                process.env.REACT_APP_SERVER_URL +
                                  "ineco/index10",
                                {
                                  params: {
                                    id: e.data.order.order_number,
                                    total: +price,
                                  },
                                }
                              )
                              .then((e) => {
                                if (e.data.fail) {
                                  setPending(false);
                                  setErrorMessage(
                                    lang[currentLanguage].error_message
                                  );
                                } else {
                                  setPending(false);
                                  setSubmitFormTag(e.data?.form);
                                }
                              });
                          } else {
                            setPending(false);
                            setAgreementInfo({
                              text: e.data.cred_info.credit_info_text,
                              icon: e.data.cred_info.icon_en,
                              order_id: e.data.order.id,
                              order_number: e.data.order.order_number,
                            });
                          }
                        } else {
                          setPending(false);
                          setErrorMessage(lang[currentLanguage].error_message);
                          // error
                        }
                      })
                    );
                  }
                }}
              >
                <span>{data.lang.buy[currentLanguage]}</span>
              </div>
              {submitFormTag && (
                <div
                  style={{ display: "none" }}
                  id="formSubmit"
                  dangerouslySetInnerHTML={{ __html: submitFormTag }}
                ></div>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
}
