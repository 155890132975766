import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import ZoomImg from "./components/ZoomImg";
import ImgModal from "./components/ImgModal";
import LoadContent from "../../../../components/LoadContent";

export default function ImgBlock({ imgs, loaded }) {
  const [activeImg, setActiveImg] = useState(0);
  const [modalImg, setModalImg] = useState(null);
  const [zoomImage, setZoomImage] = useState(null);
  const [imageNotFound, setImageNotFound] = useState(false);

  const swiperRef = useRef();

  const checkImageExists = (image) => {
    let checkImage;
    let thumbImage;
    if (typeof image == "string") {
      thumbImage = true;
      checkImage = process.env.REACT_APP_STORAGE_URL + image;
    } else {
      checkImage = process.env.REACT_APP_STORAGE_URL + image?.images_source;
    }

    const img = new Image();
    img.src = checkImage;

    img.onload = () => {
      setImageNotFound(null);
      setZoomImage(checkImage);
    };

    img.onerror = () => {
      if (thumbImage) {
        setImageNotFound(require("../../../../assets/defaultImg.png"));
      } else {
        checkImageExists(image?.thum_image_sourc);
      }
    };
    return null;
  };
  useEffect(() => {
    if (imgs?.length) {
      checkImageExists(imgs?.[activeImg]);
    } else {
      setImageNotFound(require("../../../../assets/defaultImg.png"));
    }
  }, [activeImg, imgs]);

  return (
    <>
      <div
        className={styles.imgBlock}
        style={loaded && !imgs.length ? { marginBottom: 0 } : null}
      >
        <div style={{ position: "relative", width: "100%" }}>
          {window.innerWidth > 992 && !!imgs?.length && imgs.length > 1 && (
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                onClick={() => {
                  if (activeImg !== 0) {
                    setActiveImg(activeImg - 1);
                  } else {
                    setActiveImg(imgs.length - 1);
                  }
                }}
              >
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                onClick={() => {
                  if (activeImg !== imgs.length - 1) {
                    setActiveImg(activeImg + 1);
                  } else {
                    setActiveImg(0);
                  }
                }}
              >
                <SliderNextArrowSvg />
              </div>
            </>
          )}

          {loaded ? (
            window.innerWidth > 992 ? (
              <div
                className={styles.zoomContainer}
                onClick={() => {
                  if (imgs?.[activeImg]?.images_source) {
                    setModalImg(activeImg);
                  }
                }}
              >
                {imageNotFound ? (
                  <div
                    style={{
                      background: "rgb(239,239,245)",
                      width: "100%",
                      minHeight: "510px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      style={{ background: "transparent" }}
                      src={require("../../../../assets/defaultImg.png")}
                    />
                  </div>
                ) : zoomImage ? (
                  <ZoomImg imageUrl={zoomImage} />
                ) : null}
              </div>
            ) : imgs.length ? (
              <Swiper
                grabCursor={true}
                className={styles.miniImgs}
                slidesPerView={1}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                onSlideChange={(e) => {
                  setActiveImg(e.activeIndex);
                }}
              >
                {imgs?.map((img, index) => (
                  <SwiperSlide
                    key={index}
                    className={`${styles.mainImg}`}
                    onClick={() => {
                      if (imgs?.[activeImg]?.images_source) {
                        setModalImg(activeImg);
                      }
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL + img?.images_source
                      }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div
                style={{
                  background: "rgb(239,239,245)",
                  width: "100%",

                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ background: "transparent" }}
                  src={require("../../../../assets/defaultImg.png")}
                />
              </div>
            )
          ) : (
            <LoadContent
              containerClassName={styles.mainImg}
              containerStyle={{ maxHeight: "92vw", height: "400px" }}
            />
          )}
        </div>

        <Swiper
          grabCursor={true}
          className={styles.miniImgs}
          slidesPerView={"auto"}
          style={{ background: "#fff", width: "100%", overflow: "hidden" }}
        >
          {loaded && imgs?.length
            ? imgs?.map((img, index) => (
                <SwiperSlide
                  className={`${styles.miniImg} ${
                    activeImg === index && styles.miniImgActive
                  }`}
                  onClick={() => {
                    window.innerWidth > 992
                      ? setActiveImg(index)
                      : swiperRef.current?.slideTo(index);
                  }}
                  key={index}
                >
                  <img
                    src={
                      process.env.REACT_APP_STORAGE_URL + img?.thum_image_sourc
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =
                        process.env.REACT_APP_STORAGE_URL + img?.images_source;
                    }}
                  />
                </SwiperSlide>
              ))
            : !loaded
            ? Array.from({ length: 6 }).map((_, index) => (
                <SwiperSlide key={index} className={`${styles.miniImg}`}>
                  <LoadContent containerClassName={styles.miniImg} />
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </div>
      <ImgModal
        imgs={imgs}
        modalImg={modalImg}
        closeModal={() => setModalImg(null)}
      />
    </>
  );
}
