import React from "react";
import FromToPage from "components/FromToPage";
import { useSelector } from "react-redux";
import { selectMain, selectSearch } from "store/selector";
import lang from "lang/lang";

const Title = ({ brandCategoy, styles, category, searchPage }) => {
  const { searchPageData } = useSelector(selectSearch);
  const { currentLanguage } = useSelector(selectMain);

  return (
    <>
      <FromToPage
        pages={[
          ...(searchPageData?.length
            ? searchPageData?.breadcrumbs?.map((item) => ({
                value: item?.slug,
                label: item?.["name_" + currentLanguage],
              }))
            : []),
          {
            label: searchPage
              ? lang[currentLanguage]?.search
              : category?.pageTitle?.["title_" + currentLanguage] ||
                brandCategoy?.category?.["name_" + currentLanguage],
            active: true,
          },
        ]}
      />
      <div className={styles.title}>
        <span className={styles.titleText}>
          {searchPage
            ? lang[currentLanguage]?.search_resalt
            : category?.pageTitle?.["title_" + currentLanguage] ||
              brandCategoy?.category?.["name_" + currentLanguage]}
        </span>
        {category?.pagination?.total && (
          <span className={styles.titleCount}>{category.pagination.total}</span>
        )}
      </div>
    </>
  );
};

export default Title;
