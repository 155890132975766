import Drawer from "components/Drawer";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCategory, selectMain } from "store/selector";
import { useSearchParams } from "react-router-dom";
import langData from "lang/data";

const Colors = ({
  data,
  onClearData,
  color,
  setColor,
  handleActiveAtributes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { category } = useSelector(selectCategory);
  const [selectColor, setSelectColor] = useState([]);
  const { currentLanguage } = useSelector(selectMain);
  const [searchParams, setSearchParams] = useSearchParams();

  const onClick = () => {
    setIsOpen(false);
    onClearData({});
    setColor(false);
  };

  useEffect(() => {
    if (searchParams.get("c")) {
      setSelectColor(
        searchParams
          ?.get("c")
          ?.split(",")
          ?.map((el) => +el)
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("c")]);
  let activeBrands = searchParams.has("b")
    ? JSON.parse("[" + searchParams.get("b") + "]")
    : [];
  // eslint-disable-next-line no-unused-vars
  let activeColors = searchParams.has("c")
    ? JSON.parse("[" + searchParams.get("c") + "]")
    : [];
  let activeAttributes = searchParams.has("a")
    ? searchParams.get("a").split(",")
    : [];
  const handleSelectColor = (item) => () => {
    const otherAttributes = category?.activeAttributes || {};
    const colorsWebactive = otherAttributes?.colors || [];
    const brandsWebactive = otherAttributes?.brands || [];

    const activeAttributesBack = Object.keys(otherAttributes).reduce(
      (acc, key) => {
        if (key !== "colors" && key !== "brands") {
          acc[key] = otherAttributes[key];
        }
        return acc;
      },
      {}
    );

    if (selectColor?.length && !selectColor?.includes(item)) {
      setSelectColor([...selectColor, item]);
    } else if (selectColor?.length && selectColor?.includes(item)) {
      setSelectColor(selectColor?.filter((el) => el !== item));
      if (colorsWebactive?.includes(item) && colorsWebactive?.length === 1) {
        if (brandsWebactive?.length === 1) {
          activeBrands = activeBrands.filter((e) => e !== brandsWebactive?.[0]);
          if (activeBrands.length) {
            searchParams.set("b", activeBrands);
          } else {
            searchParams.delete("b");
          }
        }

        if (Object.keys(activeAttributesBack)?.length === 1) {
          for (const key in activeAttributesBack) {
            if (activeAttributesBack.hasOwnProperty(key)) {
              const values = activeAttributesBack[key];

              activeAttributes = activeAttributes.filter(
                (e) => !values?.some((value) => e.startsWith(`${key}_${value}`))
              );
            }
          }

          if (activeAttributes.length) {
            searchParams.set("a", activeAttributes);
          } else {
            searchParams.delete("a");
          }
        }
      }
    } else {
      setSelectColor([item]);
    }
  };

  const handleItem = () => {
    const newSearchParamsT = {};

    if (searchParams.get("a")) newSearchParamsT["a"] = searchParams.get("a");
    if (selectColor.length > 0) newSearchParamsT["c"] = selectColor.join();
    if (searchParams.get("b")) newSearchParamsT["b"] = searchParams.get("b");
    if (searchParams.get("s")) newSearchParamsT["s"] = searchParams.get("s");
    if (searchParams.get("d")) newSearchParamsT["d"] = searchParams.get("d");
    if (searchParams.get("mn")) newSearchParamsT["mn"] = searchParams.get("mn");
    if (searchParams.get("mx")) newSearchParamsT["mx"] = searchParams.get("mx");
    if (searchParams.get("sp")) newSearchParamsT["sp"] = searchParams.get("sp");
    if (searchParams.get("id")) newSearchParamsT["id"] = searchParams.get("id");

    setSearchParams(new URLSearchParams(newSearchParamsT));
    // setIsOpen(false);
    // setColor(false);
    if (selectColor.length > 0)
      handleActiveAtributes(langData?.product?.color?.[currentLanguage]);
  };
  useEffect(() => {
    handleItem();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectColor]);

  useEffect(() => {
    if (color) {
      setIsOpen(true);
    }
  }, [color]);

  useEffect(() => {
    if (searchParams.get("c")) {
      setSelectColor(
        searchParams
          ?.get("c")
          ?.split(",")
          ?.map((el) => +el)
      );
    } else {
      setSelectColor([]);
      setSearchParams({
        a: searchParams.get("a") || "",
        b: searchParams.get("b") || "",
        d: searchParams.get("d") || "",
        mn: searchParams.get("mn") || "",
        mx: searchParams.get("mx") || "",
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      title={langData.product.color?.[currentLanguage]}
      handleItem={handleItem}
      onClick={onClick}
      isOpen={isOpen}
      width="599px"
      height="100dvh"
      filter="1"
      top="0px">
      <div className={styles.colors}>
        {category?.color?.map((item, index) => (
          <div
            className={styles.colorsItem}
            key={index}>
            <div
              className={
                selectColor?.includes(item?.color?.attribute_value_id)
                  ? `${styles.colorsItemCircle} ${styles.active}`
                  : styles.colorsItemCircle
              }>
              <span
                onClick={handleSelectColor(item?.color?.attribute_value_id)}
                className={styles.circle}
                style={{ backgroundColor: item?.value_en }}
              />
            </div>
            <span
              onClick={handleSelectColor(item?.color?.attribute_value_id)}
              className={
                selectColor?.includes(item?.color?.attribute_value_id)
                  ? `${styles.circleText} ${styles.circleTextActive}`
                  : styles.circleText
              }>
              {item?.color?.["name_" + currentLanguage]}
            </span>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default Colors;
