import React, { useState } from "react";
import ReactImageMagnify from "react-image-magnify";

export default function index({ imageUrl }) {
  return (
    <ReactImageMagnify
      {...{
        smallImage: {
          isFluidWidth: true,
          src: imageUrl,
        },
        largeImage: {
          src: imageUrl,
          width: 1500,
          height: 750,
        },
        enlargedImageStyle: { objectFit: "contain!important" },
        enlargedImagePosition: "over",
      }}
    />
  );
}
