import Drawer from "components/Drawer";
import styles from "./styles.module.css";
import FilterItemPrice from "../filterPrice/index";
import { useSelector } from "react-redux";
import { selectCategory, selectMain } from "store/selector";
import { useSearchParams } from "react-router-dom";
import { useState, useMemo } from "react";
import langData from "lang/data";
import Switch from "../switch";

const Cost = ({
  data,
  onClearData,
  setCost,
  handleActiveAtributes,
  setCurrentPage,
}) => {
  const { category } = useSelector(selectCategory);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectCost, setSelectCost] = useState({});
  const { currentLanguage } = useSelector(selectMain);
  const [switchActive, setSwitchActive] = useState(false);
 
  const onClick = () => {
    setCost(false);
    onClearData(false);
  };

  const handleCost = (mn, mx) => {
    setSelectCost({
      mn,
      mx,
    });
  };

  const handleSwitch = () => {setSwitchActive(!switchActive);}

  const onCost = () => {
    setCurrentPage(1);
    const newSearchParams = {};
    
    if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
    if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
    if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
    if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
    if (searchParams.get("b")) newSearchParams["b"] = searchParams.get("b");
    if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
    if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
    if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
    if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");
  
    setSearchParams(new URLSearchParams(newSearchParams));
   
   // setCost(false);

    handleActiveAtributes(langData?.product?.cost?.[currentLanguage]);
  };

  useMemo(() => {
    if (switchActive) {
      setCurrentPage(1);
      const newSearchParams = {};

      if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
      if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
      if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
      if (searchParams.get("b")) newSearchParams["b"] = searchParams.get("b");
      if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
      if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
      if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
      if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");
      newSearchParams["d"] = 1;
      
      setSearchParams(new URLSearchParams(newSearchParams));
    } else {
      const newSearchParams = {};

      if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
      if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
      if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
      if (searchParams.get("b")) newSearchParams["b"] = searchParams.get("b");
      if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
      if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
      if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
      if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");
      if (searchParams.get("p")) newSearchParams["p"] = searchParams.get("p");
      if (searchParams.get("page")) newSearchParams["page"] = searchParams.get("page");
      setSearchParams(new URLSearchParams(newSearchParams));
    }
  }, [switchActive, searchParams, setSearchParams]);

  useMemo(() => {
    if (searchParams.get("d")) {
      setSwitchActive(searchParams?.get("d"));
    } else {
      setSwitchActive(false);
      const newSearchParams = {};
      if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
      if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
      if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
      if (searchParams.get("b")) newSearchParams["b"] = searchParams.get("b");
      if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
      if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
      if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
      if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");
      if (searchParams.get("p")) newSearchParams["p"] = searchParams.get("p");
      if (searchParams.get("page")) newSearchParams["page"] = searchParams.get("page");
      setSearchParams(new URLSearchParams(newSearchParams));
    }
  }, [searchParams, setSearchParams]);

  return (
    <Drawer
      title={langData?.product?.cost?.[currentLanguage]}
      handleItem={onCost}
      onClick={onClick}
      isOpen={data}
      width="599px"
      height="100dvh"
      filter="1"
      top="0px"
    >
      <div className={styles.cost}>
        <FilterItemPrice
            min={searchParams.get("mn") ?? category?.min_price_lowest}
            max={searchParams.get("mx") ?? category?.max_price_highest}
          defaultPrices={{
            min: category?.initial_min_price_lowest,
            max: category?.initial_max_price_highest,
          }}
          handleCost={handleCost}
        />
        {!!category?.discount && (
          <div className={styles.switch}>
            <p>{langData.product.discount?.[currentLanguage]}</p>
            <Switch
              isOn={switchActive ? false : true}
              handleToggle={handleSwitch}
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default Cost;
