import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import UseOutSideClick from "../../../../../../helpers/UseOutSideClick";
import ZoomImg from "../ZoomImg";
import { SwiperSlide, Swiper } from "swiper/react";
import SliderNextArrowSvg from "../../../../../../assets/SVG/SliderNextArrowSvg";

export default function ImgModal({ imgs, modalImg, closeModal }) {
  const [activeImg, setActiveImg] = useState(modalImg);
  const mainRef = useRef();
  UseOutSideClick(mainRef, closeModal);

  const swiperRef = useRef();
  useEffect(() => {
    setActiveImg(modalImg);
  }, [modalImg]);
  if (typeof modalImg !== "number") return null;

  return (
    <div className={styles.background}>
      <div className={styles.main} ref={mainRef}>
        <span className={styles.closeBtn} onClick={closeModal}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
          </svg>
        </span>
        <div className={styles.zoomContainer}>
          {!!imgs?.length && imgs.length > 1 && (
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                onClick={() => {
                  if (activeImg !== 0) {
                    setActiveImg(activeImg - 1);
                  } else {
                    setActiveImg(imgs.length - 1);
                  }
                }}
              >
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                onClick={() => {
                  if (activeImg !== imgs.length - 1) {
                    setActiveImg(activeImg + 1);
                  } else {
                    setActiveImg(0);
                  }
                }}
              >
                <SliderNextArrowSvg />
              </div>
            </>
          )}
          <ZoomImg
            imageUrl={
              process.env.REACT_APP_STORAGE_URL +
              imgs?.[activeImg]?.images_source
            }
          />
        </div>
        <Swiper
          grabCursor={true}
          className={styles.miniImgs}
          slidesPerView={"auto"}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          style={{ background: "#fff", width: "100%", overflow: "hidden" }}
        >
          {imgs?.map((img, index) => (
            <SwiperSlide
              className={`${styles.miniImg} ${
                activeImg === index && styles.miniImgActive
              }`}
              onClick={() => {
                setActiveImg(index);
              }}
              key={index}
            >
              <img
                src={process.env.REACT_APP_STORAGE_URL + img?.thum_image_sourc}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    process.env.REACT_APP_STORAGE_URL + img?.images_source;
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
