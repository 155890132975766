import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import Sliders from "./components/Sliders";
import { useDispatch, useSelector } from "react-redux";
import { getMainInfo } from "store/HomeSlice";
import Sales from "./components/Sales";
import FeatureCategories from "./components/FeatureCategories";
import BanerSlider from "../../components/BanerSlider";
import Blog from "./components/Blog";
import Brand from "../../components/Brand";
import FooterBaner from "./components/FooterBaner";
import ButtonArrowSvg from "assets/SVG/ButtonArrowSvg";
import Paralax from "./components/Paralax";
import SpecialOffer from "./components/SpecialOffer";
import langData from "lang/data";
import ProductsWithSlide from "../../components/ProductsWithSlide";
import TreeInOne from "./components/TreeInOne/index";
import BanerSliders from "./components/BanerSliders";
import { Stories } from "components/Stories/Stories";
import { StoriesModal } from "components/Stories/StoriesModal";
import useWindowSize from "components/useWindowSize";

export default function Home() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const dispatch = useDispatch();
  const { bestDealProduct, topPicksProduct, topRatingProduct, bannerSliders } =
    useSelector(({ home }) => home);
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const fullscreenDivRef = useRef(null);

  const scrollListnerFunc = (e) => {
    if (document.body.parentNode.scrollTop > 500) {
      setShowTopBtn(true);
    } else if (document.body.parentNode.scrollTop < 500) {
      setShowTopBtn(false);
    }
  };

  useEffect(() => {
    dispatch(getMainInfo());
    document.addEventListener("scroll", scrollListnerFunc);
    return () => {
      document.removeEventListener("scroll", scrollListnerFunc);
    };
  }, [dispatch]);

  const saleBackgroundColor = "#f7f7fb";
  const { width: windowWidth } = useWindowSize();

  const handleFullScreenToggle = () => {
    if (windowWidth <= 768) {
      if (!isFullScreen) {
        // Enter full-screen mode
        if (fullscreenDivRef.current.requestFullscreen) {
          fullscreenDivRef.current
            .requestFullscreen()
            .then(() => {
              setIsFullScreen(true);
            })
            .catch((err) => {
              console.error(
                "Error attempting to enable full-screen mode:",
                err.message
              );
            });
        } else if (fullscreenDivRef.current.mozRequestFullScreen) {
          // Firefox
          fullscreenDivRef.current
            .mozRequestFullScreen()
            .then(() => {
              setIsFullScreen(true);
            })
            .catch((err) => {
              console.error(
                "Error attempting to enable full-screen mode:",
                err.message
              );
            });
        } else if (fullscreenDivRef.current.webkitRequestFullscreen) {
          // Chrome, Safari, and Opera
          fullscreenDivRef.current
            .webkitRequestFullscreen()
            .then(() => {
              setIsFullScreen(true);
            })
            .catch((err) => {
              console.error(
                "Error attempting to enable full-screen mode:",
                err.message
              );
            });
        } else if (fullscreenDivRef.current.msRequestFullscreen) {
          // IE/Edge
          fullscreenDivRef.current
            .msRequestFullscreen()
            .then(() => {
              setIsFullScreen(true);
            })
            .catch((err) => {
              console.error(
                "Error attempting to enable full-screen mode:",
                err.message
              );
            });
        }
      } else {
        // Exit full-screen mode
        if (document.exitFullscreen) {
          document
            .exitFullscreen()
            .then(() => {
              setIsFullScreen(false);
            })
            .catch((err) => {
              console.error(
                "Error attempting to exit full-screen mode:",
                err.message
              );
            });
        } else if (document.mozCancelFullScreen) {
          // Firefox
          document
            .mozCancelFullScreen()
            .then(() => {
              setIsFullScreen(false);
            })
            .catch((err) => {
              console.error(
                "Error attempting to exit full-screen mode:",
                err.message
              );
            });
        } else if (document.webkitExitFullscreen) {
          // Chrome, Safari, and Opera
          document
            .webkitExitFullscreen()
            .then(() => {
              setIsFullScreen(false);
            })
            .catch((err) => {
              console.error(
                "Error attempting to exit full-screen mode:",
                err.message
              );
            });
        } else if (document.msExitFullscreen) {
          // IE/Edge
          document
            .msExitFullscreen()
            .then(() => {
              setIsFullScreen(false);
            })
            .catch((err) => {
              console.error(
                "Error attempting to exit full-screen mode:",
                err.message
              );
            });
        }
      }
    }
  };

  return (
    <>
      {window.innerWidth > 1440 && <Paralax />}

      <div className={styles.container}>
        <div style={{ overflow: "hidden" }}>
          <div
            className={styles.topBtn}
            style={{ opacity: +showTopBtn }}
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }>
            <ButtonArrowSvg className={styles.arrow} />
          </div>

          <Sliders />
          <Stories
            isOpen={isOpen}
            setSelectedStoryIndex={setSelectedStoryIndex}
            setIsOpen={setIsOpen}
            handleFullScreenToggle={handleFullScreenToggle}
          />
          <StoriesModal
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            selectedStoryIndex={selectedStoryIndex}
            fullscreenDivRef={fullscreenDivRef}
            handleFullScreenToggle={handleFullScreenToggle}
          />
          {/* <WorkInformations /> */}
          <FeatureCategories />
          <SpecialOffer />

          <Sales saleBackgroundColor={saleBackgroundColor} />
          <BanerSliders />
          <ProductsWithSlide
            productsData={bestDealProduct}
            title={langData.titles.BestDealProduct[currentLanguage]}
          />
          <TreeInOne />
          <BanerSlider bannerSliders={bannerSliders} />
          {!!topPicksProduct?.length && (
            <ProductsWithSlide
              productsData={topPicksProduct}
              title={langData.titles.TopPicksProduct[currentLanguage]}
            />
          )}
          <ProductsWithSlide
            productsData={topRatingProduct}
            title={langData.titles.TopRatingProduct[currentLanguage]}
          />
          <Blog
            btn={true}
            title="default"
          />
          <Brand />
          <FooterBaner />
        </div>
      </div>
    </>
  );
}
