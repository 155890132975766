import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Drawer from "components/Drawer";
import DropDownAttribute from "screens/Category/components/DropDounAttribute";
import FilterItemPrice from "../filterPrice/index";
import { DATA } from "../../../../data";
import { useSelector } from "react-redux";
import { selectMain, selectCategory } from "store/selector";
import langData from "lang/data";
import { useAsyncValue, useSearchParams } from "react-router-dom";
import checkBase64 from "helpers/checkBase64";
import Switch from "../switch";
import lang from "lang/lang";
import DiagonalAttributeRange from "../diagonalAttributeRange";
// import { isCompositeComponent } from "react-dom/test-utils";
let searchTimeOutId;

const Filter = ({ data, onClearData, handleDeleteAttribute }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [discount, setDiscount] = useState(false);
  const { currentLanguage } = useSelector(selectMain);
  const { category } = useSelector(selectCategory);
  // const { searchPage } = useSelector(selectSearch);
  const [search, setSearch] = useState("");
  // disabling the inputs, while the rendering goes on
  const [discountDisabled, setDiscountDisabled] = useState(false);
  const [searchDisabled, setSearchDisabled] = useState(false);
  const [priceDisabled, setPriceDisabled] = useState(false);

  // fool pack
  // setSearchDisabled(true);
  // setDiscountDisabled(true);
  // setPriceDisabled(true);

  const [searchParams, setSearchParams] = useSearchParams();

  let activeBrands = searchParams.has("b")
    ? JSON.parse("[" + searchParams.get("b") + "]")
    : [];
  let activeColors = searchParams.has("c")
    ? JSON.parse("[" + searchParams.get("c") + "]")
    : [];

  let activeAttributes = searchParams.has("a")
    ? searchParams.get("a").split(",")
    : [];
  const onClick = () => {
    setIsOpen(false);
    onClearData({});
  };

  useEffect(() => {
    if (searchParams.get("st") === null) {
      setSearch("");
    }
  }, [searchParams]);

  useEffect(() => {
    if (data?.name_en === "filter") {
      setIsOpen(true);
    }
  }, [data?.name_en]);

  const handleSearch = (value) => {
    setDiscountDisabled(true);
    setPriceDisabled(true);

    setSearch(value);
    const isEmptyValue = !/[a-zA-Z0-9]/.test(value);

    if (searchTimeOutId) {
      clearTimeout(searchTimeOutId);
    }
    searchTimeOutId = setTimeout(() => {
      searchParams.delete("page");
      if (value && !isEmptyValue) {
        searchParams.set("st", value);
      } else {
        searchParams.delete("st");
      }
      setSearchParams(searchParams);

      setDiscountDisabled(false);
      setPriceDisabled(false);
    }, 500);
  };

  const handleDiscount = () => {
    setSearchDisabled(true);
    setPriceDisabled(true);

    searchParams.delete("page");
    if (discount) {
      searchParams.delete("d");
    } else {
      searchParams.set("d", 1);
    }
    setDiscount(!discount);
    setSearchParams(searchParams);

    setSearchDisabled(false);
    setPriceDisabled(false);
  };

  const handleSelectSorting = (e) => {
    setSearchDisabled(true);
    setDiscountDisabled(true);
    setPriceDisabled(true);

    let currentEl = document.getElementById(e);
    let parentForm = currentEl.parentNode.parentNode.parentNode;
    let otherInputs = parentForm.querySelectorAll(`input:not(#${e})`);
    otherInputs.forEach((item) => item.setAttribute("disabled", true));

    searchParams.delete("page");
    if (searchParams.get("s") === e) {
      searchParams.delete("s");
    } else {
      searchParams.set("s", e);
    }
    setSearchParams(searchParams);

    setSearchDisabled(false);
    setDiscountDisabled(false);
    setPriceDisabled(false);
    otherInputs.forEach((item) => item.removeAttribute("disabled"));
  };

  const handleSelectBrand = (brand_id) => {
    setSearchDisabled(true);
    setDiscountDisabled(true);
    setPriceDisabled(true);
    const otherAttributes = category?.activeAttributes || {};
    const colorsWebactive = otherAttributes?.colors || [];
    const brandsWebactive = otherAttributes?.brands || [];
    const activeAttributesBack = Object.keys(otherAttributes).reduce(
      (acc, key) => {
        if (key !== "colors" && key !== "brands") {
          acc[key] = otherAttributes[key];
        }
        return acc;
      },
      {}
    );

    searchParams.delete("page");
    if (activeBrands.includes(brand_id)) {
      activeBrands = activeBrands.filter((e) => e !== brand_id);
      if (brandsWebactive?.includes(brand_id) && brandsWebactive.length === 1) {
        if (colorsWebactive?.length === 1) {
          activeColors = activeColors.filter((e) => e !== colorsWebactive?.[0]);
        }

        if (Object.keys(activeAttributesBack)?.length === 1) {
          for (const key in activeAttributesBack) {
            if (activeAttributesBack.hasOwnProperty(key)) {
              const values = activeAttributesBack[key];

              activeAttributes = activeAttributes.filter(
                (e) => !values?.some((value) => e.startsWith(`${key}_${value}`))
              );
            }
          }

          if (activeAttributes.length) {
            searchParams.set("a", activeAttributes);
          } else {
            searchParams.delete("a");
          }
        }
      }
    } else {
      activeBrands.push(brand_id);
    }

    if (activeBrands.length) {
      searchParams.set("b", activeBrands);
    } else {
      searchParams.delete("b");
    }

    setSearchParams(searchParams);

    setSearchDisabled(false);
    setDiscountDisabled(false);
    setPriceDisabled(false);
  };

  const handleSelectColor = (id) => {
    setSearchDisabled(true);
    setDiscountDisabled(true);
    setPriceDisabled(true);
    const otherAttributes = category?.activeAttributes || {};
    const colorsWebactive = otherAttributes?.colors || [];
    const brandsWebactive = otherAttributes?.brands || [];

    const activeAttributesBack = Object.keys(otherAttributes).reduce(
      (acc, key) => {
        if (key !== "colors" && key !== "brands") {
          acc[key] = otherAttributes[key];
        }
        return acc;
      },
      {}
    );
    searchParams.delete("page");
    if (activeColors.includes(id)) {
      activeColors = activeColors.filter((e) => e !== id);
      if (colorsWebactive?.includes(id) && colorsWebactive?.length === 1) {
        if (brandsWebactive?.length === 1) {
          activeBrands = activeBrands.filter((e) => e !== brandsWebactive?.[0]);
          if (activeBrands.length) {
            searchParams.set("b", activeBrands);
          } else {
            searchParams.delete("b");
          }
        }

        if (Object.keys(activeAttributesBack)?.length === 1) {
          for (const key in activeAttributesBack) {
            if (activeAttributesBack.hasOwnProperty(key)) {
              const values = activeAttributesBack[key];

              activeAttributes = activeAttributes.filter(
                (e) => !values?.some((value) => e.startsWith(`${key}_${value}`))
              );
            }
          }

          if (activeAttributes.length) {
            searchParams.set("a", activeAttributes);
          } else {
            searchParams.delete("a");
          }
        }

        activeColors = activeColors.filter((e) => e !== colorsWebactive?.[0]);
      }
    } else {
      activeColors.push(id);
    }

    if (activeColors.length) {
      searchParams.set("c", activeColors);
    } else {
      searchParams.delete("c");
    }

    setSearchParams(searchParams);

    setSearchDisabled(false);
    setDiscountDisabled(false);
    setPriceDisabled(false);
  };

  const handleSelectAttribute = (item, attributeType) => {
    searchParams.delete("page");
    let attribute = `${item?.attribute_id}_${item?.id}`;
    const otherAttributes = category?.activeAttributes || {};
    const colorsWebactive = otherAttributes?.colors || [];
    const brandsWebactive = otherAttributes?.brands || [];
    const activeAttributesBack = Object.keys(otherAttributes).reduce(
      (acc, key) => {
        if (key !== "colors" && key !== "brands") {
          acc[key] = otherAttributes[key];
        }
        return acc;
      },
      {}
    );

    if (attributeType === "screenSize") {
    }
    if (activeAttributes.includes(attribute)) {
      if (attributeType === "screenSize") {
        activeAttributes = activeAttributes.filter((e) => e === attribute);
      } else {
        activeAttributes = activeAttributes.filter((e) => e !== attribute);
        if (
          activeAttributesBack[item.attribute_id] &&
          activeAttributesBack[item.attribute_id].length === 1
        ) {
          for (const key in activeAttributesBack) {
            if (activeAttributesBack.hasOwnProperty(key)) {
              const values = activeAttributesBack[key];

              activeAttributes = activeAttributes.filter(
                (e) => !values?.some((value) => e.startsWith(`${key}_${value}`))
              );
            }
          }

          if (brandsWebactive?.length === 1) {
            activeBrands = activeBrands.filter(
              (e) => e !== brandsWebactive?.[0]
            );
            if (activeBrands.length) {
              searchParams.set("b", activeBrands);
            } else {
              searchParams.delete("b");
            }
          }

          if (colorsWebactive?.length === 1) {
            activeColors = activeColors.filter(
              (e) => e !== colorsWebactive?.[0]
            );
          }
        }
      }
    } else {
      activeAttributes.push(attribute);
    }

    if (activeAttributes.length) {
      searchParams.set("a", activeAttributes);
    } else {
      searchParams.delete("a");
    }

    setSearchParams(searchParams);
  };

  useEffect(() => {
    setSearch(searchParams.get("st"));
    setDiscount(searchParams.get("d"));
  }, []);

  return (
    <Drawer
      filter
      title={langData?.product?.filter?.[currentLanguage]}
      onClick={onClick}
      isOpen={isOpen}
      height="100dvh"
      top="0px">
      <div className={styles.filter}>
        <div className={styles.container}>
          <div className={styles.costContainer}>
            <p>{lang[currentLanguage]?.search}</p>
            <input
              style={{
                width: "100%",
                height: "40px",
                marginBottom: "30px",
                border: "solid #ccc 1px",
                outline: "none",
                padding: "0 10px",
              }}
              placeholder={lang[currentLanguage]?.search}
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              disabled={searchDisabled}
              id="main_search"
            />
          </div>
          {!!category?.discount && (
            <div className={styles.switch}>
              <p>{langData.product.discount?.[currentLanguage]}</p>
              <Switch
                disabled={discountDisabled}
                isOn={!discount}
                handleToggle={(e) => {
                  handleDiscount();
                }}
              />
            </div>
          )}
          <div className={styles.costContainer}>
            <p>{langData.product.cost?.[currentLanguage]}</p>
            <div className={styles.cost}>
              <FilterItemPrice
                min={searchParams.get("mn") ?? category?.min_price_lowest}
                max={searchParams.get("mx") ?? category?.max_price_highest}
                defaultPrices={{
                  min: category?.initial_min_price_lowest,
                  max: category?.initial_max_price_highest,
                }}
                disabled={priceDisabled}
              />
            </div>
          </div>
          <DropDownAttribute
            checked={searchParams.get("s")}
            title={langData?.product.sorting?.[currentLanguage]}>
            <div className={styles.sort}>
              <form>
                {DATA?.map((item, key) => (
                  <div
                    className={styles.sortingItem}
                    key={key}>
                    <p className={styles.checkboxWrapper}>
                      <span onClick={() => handleSelectSorting(item?.value)}>
                        {item?.["label_" + currentLanguage]}
                      </span>
                      <input
                        type="checkbox"
                        id={item?.value}
                        name="radio-group"
                        checked={searchParams.get("s") == item.value}
                      />
                      <label
                        for={item?.value}
                        onClick={() => handleSelectSorting(item?.value)}
                      />
                    </p>
                  </div>
                ))}
              </form>
            </div>
          </DropDownAttribute>
          {!!category?.brandList && (
            <DropDownAttribute
              checked={searchParams.get("b")}
              title={langData?.product.manufactor?.[currentLanguage]}>
              <div className={styles.manufactur}>
                <form>
                  {category?.brandList?.map((item, index) => (
                    <div
                      key={index}
                      className={styles.sortingItem}>
                      <p className={styles.checkboxWrapper}>
                        <div className={styles.brandImg}>
                          <img
                            onClick={() => handleSelectBrand(item?.id)}
                            src={
                              item?.logo
                                ? checkBase64(item?.logo)
                                  ? "data:image/png;base64," + item.logo
                                  : process.env.REACT_APP_STORAGE_URL +
                                    item.logo
                                : "https://vlv.am/public/frontend/default/img/brand_image.png"
                            }
                            alt="brand"
                          />
                        </div>
                        <input
                          type="checkbox"
                          id={item?.id}
                          name="radio-group"
                          checked={activeBrands?.includes(item?.id)}
                        />
                        <label
                          onClick={() => handleSelectBrand(item?.id)}
                          for={item?.id}
                        />
                      </p>
                    </div>
                  ))}
                </form>
              </div>
            </DropDownAttribute>
          )}

          {!!category?.color?.length && (
            <DropDownAttribute
              checked={searchParams.get("c")}
              title={langData.product.color?.[currentLanguage]}>
              {category?.color?.map((item, index) => (
                <div
                  key={index}
                  className={styles.colorsItem}>
                  <div
                    className={
                      activeColors?.includes(item?.color?.attribute_value_id)
                        ? `${styles.colorsItemCircle} ${styles.active}`
                        : styles.colorsItemCircle
                    }>
                    <span
                      onClick={() =>
                        handleSelectColor(item?.color?.attribute_value_id)
                      }
                      className={styles.circle}
                      style={{
                        backgroundColor: item?.value_en,
                      }}
                    />
                  </div>
                  <span
                    onClick={() =>
                      handleSelectColor(item?.color?.attribute_value_id)
                    }
                    className={
                      activeColors?.includes(item?.color?.attribute_value_id)
                        ? `${styles.circleText} ${styles.circleTextActive}`
                        : styles.circleText
                    }>
                    {item?.color?.["name_" + currentLanguage]}
                  </span>
                </div>
              ))}
            </DropDownAttribute>
          )}

          {category?.new_attributes?.map((item, index) => (
            <DropDownAttribute
              key={index}
              checked={item?.values?.some((el) =>
                activeAttributes?.includes(`${el?.attribute_id}_${el?.id}`)
              )}
              title={item?.["name_" + currentLanguage]}>
              {item?.styles && item?.styles === 1 ? (
                <DiagonalAttributeRange
                  attribute={item}
                  activeAttributes={activeAttributes}
                  handleSelectAttribute={handleSelectAttribute}
                  handleDeleteAttribute={handleDeleteAttribute}
                />
              ) : (
                <div
                  key={item?.id}
                  className={styles.manufactur}>
                  <form>
                    {item?.values?.map((value) => (
                      <div
                        key={value?.id}
                        className={styles.sortingItem}>
                        <p className={styles.checkboxWrapper}>
                          <span
                            onClick={() => {
                              handleSelectAttribute(value);
                            }}>
                            {value?.["value_" + currentLanguage]}
                          </span>
                          <input
                            type="checkbox"
                            id={value?.id}
                            name="radio-group"
                            checked={activeAttributes?.includes(
                              `${value?.attribute_id}_${value?.id}`
                            )}
                          />
                          <label
                            onClick={() => {
                              handleSelectAttribute(value);
                            }}
                            for={value?.id}
                          />
                        </p>
                      </div>
                    ))}
                  </form>
                </div>
              )}
            </DropDownAttribute>
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default Filter;
