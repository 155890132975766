import React from "react";

export default function GiftSvg() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.15625L9.1875 1.21875C9.65625 0.46875 10.5 0 11.4062 0H11.5C12.875 0 14 1.125 14 2.5C14 3.09375 13.8125 3.59375 13.5 4H14.5C15.3125 4 16 4.6875 16 5.5V7.5C16 8.15625 15.5625 8.71875 15 8.9375V14C15 15.125 14.0938 16 13 16H3C1.875 16 1 15.125 1 14V8.9375C0.40625 8.71875 0 8.15625 0 7.5V5.5C0 4.6875 0.65625 4 1.5 4H2.46875C2.15625 3.59375 2 3.09375 2 2.5C2 1.125 3.09375 0 4.5 0H4.5625C5.46875 0 6.3125 0.46875 6.78125 1.21875L8 3.15625ZM11.4062 1C10.8438 1 10.3438 1.3125 10.0312 1.75L8.625 4H11.5C12.3125 4 13 3.34375 13 2.5C13 1.6875 12.3125 1 11.5 1H11.4062ZM7.34375 4L5.9375 1.75C5.625 1.3125 5.125 1 4.5625 1H4.5C3.65625 1 3 1.6875 3 2.5C3 3.34375 3.65625 4 4.5 4H7.34375ZM1.5 5C1.21875 5 1 5.25 1 5.5V7.5C1 7.78125 1.21875 8 1.5 8H7.5V5H1.5ZM8.5 8H14.5C14.75 8 15 7.78125 15 7.5V5.5C15 5.25 14.75 5 14.5 5H8.5V8ZM7.5 9H2V14C2 14.5625 2.4375 15 3 15H7.5V9ZM8.5 15H13C13.5312 15 14 14.5625 14 14V9H8.5V15Z"
        fill="white"
      ></path>
    </svg>
  );
}
