import React from "react";
import FromToPage from "../../components/FromToPage";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../../components/LoadContent/index";
import langData from "../../lang/data";
import { useEffect } from "react";
import { getDeliveryPageData } from "../../store/MainSlice";
import styles from "./style.module.css";
import lang from "../../lang/lang";

export default function Delivery() {
  const dispatch = useDispatch();
  const { currentLanguage, deliveryData } = useSelector(({ main }) => main);

  useEffect(() => {
    dispatch(getDeliveryPageData());
  }, []);
  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: lang[currentLanguage].shipping,
            value: "/delivery",
            active: true,
          },
        ]}
      />
      <div className={styles.banner}>
        {!deliveryData ? (
          <LoadContent containerClassName={styles.LoadContentGeneralBanner} />
        ) : (
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              deliveryData?.banner["image_" + currentLanguage]
            }
            alt="img"
          />
        )}
      </div>
      <div className={styles.deliveryTypeBlck}>
        {deliveryData?.delivery["banners_" + currentLanguage].map(
          (item, index) =>
            !deliveryData ? (
              <LoadContent containerClassName={styles.LoadContentDelivery} />
            ) : (
              <div className={styles.deliveTypeName} key={index + 1}>
                <div>{item}</div>
              </div>
            )
        )}
      </div>
      {!deliveryData ? (
        <LoadContent containerClassName={styles.LoadContentText} />
      ) : (
        <element
          dangerouslySetInnerHTML={{
            __html: deliveryData?.delivery["text_1_" + currentLanguage],
          }}
        ></element>
      )}

      <div className={styles.deliveryStepsBlck}>
        {!deliveryData ? (
          <LoadContent containerClassName={styles.LoadContentTitle} />
        ) : (
          <element
            className={styles.second_title}
            dangerouslySetInnerHTML={{
              __html: deliveryData?.delivery["title_" + currentLanguage],
            }}
          ></element>
        )}
        <div className={styles.stepsofDeliv}>
          {deliveryData?.delivery["info_" + currentLanguage].map(
            (item, index) =>
              !deliveryData ? (
                <LoadContent containerClassName={styles.LoadContentSteps} />
              ) : (
                <div className={styles.steps}>
                  <div className={styles.stepImg}>
                    <img src={process.env.REACT_APP_STORAGE_URL + item?.icon} />
                  </div>
                  <span className={styles.sepsTitle}>{item.title}</span>
                </div>
              )
          )}
        </div>
      </div>
      <div className={styles.deliveryStepsBlck}>
        {!deliveryData ? (
          <LoadContent containerClassName={styles.LoadContentSecond_title} />
        ) : (
          <element
            className={styles.second_title}
            // dangerouslySetInnerHTML={{
            //   __html: deliveryData?.delivery["title_" + currentLanguage],
            // }}
          >
            Условия
          </element>
        )}
        <div className={styles.terms}>
          {deliveryData?.delivery["condition_" + currentLanguage].map(
            (item, index) =>
              !deliveryData ? (
                <LoadContent containerClassName={styles.LoadContentTerm} />
              ) : (
                <div className={styles.term} key={index + 1}>
                  <div className={styles.termStep}>0{Number(index + 1)}</div>
                  <span className={styles.termName}>{item}</span>
                </div>
              )
          )}
        </div>
        {!deliveryData ? (
          <LoadContent containerClassName={styles.LoadContentLastInfo} />
        ) : (
          <element
            className={styles.lastInfo}
            dangerouslySetInnerHTML={{
              __html: deliveryData?.delivery["text_2_" + currentLanguage],
            }}
          ></element>
        )}
      </div>
    </div>
  );
}
