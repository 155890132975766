import React, { useEffect, useRef, useState } from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";
import CompareSvg from "../../assets/SVG/CompareSvg";
import HeartSvg from "../../assets/SVG/HeartSvg";
import ShareSvg from "../../assets/SVG/ShareSvg";
import {
  addCardStore,
  addCompare,
  addCompares,
  addFavorites,
  addShopCartCount,
  addWishList,
  getProductInfo,
  getRecentProducts,
  getRelatedProducts,
  getSimilarProducts,
  onClickInstallingBtn,
  removeCompares,
  removeFavorites,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setProductInfo,
  setShopCartProductAnimation,
} from "../../store/MainSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import UseCalcPrice from "../../helpers/UseCalcPrice";
import ImgBlock from "./components/ImgBlock";
import ShopCartSvg from "../../assets/SVG/ShopCartSvg";
import ProductPageCompare from "./components/ProductPageCompare";
import ProductsWithSlide from "../../components/ProductsWithSlide/index";
import WorkInformations from "../Home/components/WorkInformations";
import lang from "../../lang/lang";
import langData from "../../lang/data";
import { credit24Month, credit36Month } from "../../helpers/creditCalculator";
import StikckyFooter from "./components/StikckyFooter";
import CreditModal from "../../components/CreditMobal";
import UseOutSideClick from "../../helpers/UseOutSideClick";
import InfoModal from "./components/InfoModal";
import GiftSvg from "../../assets/SVG/GiftSvg";
import checkBase64 from "../../helpers/checkBase64";
import LoadContent from "../../components/LoadContent";
import RelatedProducts from "../../components/RelatedProducts/index";
import { productThunk } from "store/slices/productSlice/thunk";

export default function ProductPage() {
  const [count, setCount] = useState(1);
  const [showMoreAtributes, setShowMoreAtributes] = useState(false);
  const [showBottomSticky, setShowBottomSticky] = useState(false);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [showShareDropDown, setShowShareDropDown] = useState(false);
  const [choosenGift, setChoosenGift] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState();
  const [installingBtnBlockActive, setInstallingBtnBlockActive] =
    useState(true);
  const { productId } = useParams();
  const {
    productInfo,
    currentLanguage,
    currentCurrency,
    similarProducts,
    compares,
    favorites,
  } = useSelector(({ main }) => main);
  const {colors} = useSelector(({product}) => product)
  const navigate = useNavigate()
  const product = productInfo?.product;
  const breadcrumbs = productInfo?.breadcrumbs;

  const dispatch = useDispatch();
  const shareDropDownRef = useRef();
  const addToCartBtnRef = useRef();
  const addFavoriteBtnRef = useRef();
  const addToCompareBtnRef = useRef();
  let choose_your_gift = {
    en: "choose your gift",
    hy: "Ընտրեք ձեր նվերը",
    ru: "Выберите ваш подарок",
  };
  const handleScroll = (e) => {
    if (document.body?.parentNode.scrollTop >= 800) {
      setShowBottomSticky(true);
    } else {
      setShowBottomSticky(false);
    }
  };

  UseOutSideClick(shareDropDownRef, () => setShowShareDropDown(false));
  // const handlerChooseGift = (id) => {
  //   setChoosenGift(id);
  // };
  useEffect(() => {
    setCount(1);
    setShowMoreAtributes(false);
    setShowBottomSticky(false);
    setShowCreditModal(false);
    setShowShareDropDown(false);
    setShowInfoModal(null);
    setInstallingBtnBlockActive(true);
    dispatch(setProductInfo(null));
    dispatch(getProductInfo(productId));
    dispatch(getSimilarProducts(productId));
  }, [productId]);
  useEffect(() => {
    if (product?.skus[0].product_id) {
      dispatch(getRelatedProducts(product?.skus[0].product_id));
      dispatch(getRecentProducts(product?.skus[0].product_id));
    }
    // if (product?.gift_images) {
    //   setChoosenGift(product?.gift_images[0]?.product_id);
    // } else {
    //   setChoosenGift(null);
    // }
  }, [product]);

  useEffect(() => {
    dispatch(
      onClickInstallingBtn(
        (product?.skus?.[0].promo_price || product?.skus?.[0].selling_price) -
          ((!installingBtnBlockActive && product?.installing_price) || 0)
      )
    );
  }, [installingBtnBlockActive]);

  useEffect(() => {
    if (window.innerWidth > 992)
      window.addEventListener("scroll", handleScroll);
    return () => {
      if (window.innerWidth > 992)
        window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
   dispatch(productThunk(productId))
  },[productId])

  if (productInfo?.error) {
    window.location.href = "https://vlv.am/404";
    return null;
  }
  return (
    <>
      <div className="wrapper">
        {breadcrumbs?.length && Object.keys(productInfo || {}).length ? (
          <FromToPage
            pages={[
              ...breadcrumbs?.map((item) => ({
                label: item["name_" + currentLanguage],
                value: `${
                  currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/category/filter/${item?.slug}`,

                active: false,
              })),
              {
                label: product?.brand.name + " " + product?.product_name,
                value: "/",
                active: true,
              },
            ]}
          />
        ) : (
          <LoadContent
            containerStyle={{
              height: "30px",
              marginTop: "30px",
              marginBottom: "37px",
            }}
          />
        )}

        <div className={styles.main}>
          <div className={styles.sliderBlock}>
            {
              <ImgBlock
                loaded={Object.keys(productInfo || {}).length}
                imgs={product?.gallary_images_api}
              />
            }

            {Object.keys(product || {}).length ? (
              <div className={styles.infoBlock}>
                <div className={styles.row1}>
                  <span className={styles.prodCategory}>
                    {product?.categories?.[0]?.["name_" + currentLanguage]}
                  </span>
                  <div className={styles.shareBtns}>
                    <div className={styles.compareHeart}>
                      <div
                        ref={addToCompareBtnRef}
                        onClick={() => {
                          if (!compares.includes(product?.skus?.[0]?.id)) {
                            if (
                              product?.gallary_images_api?.[0]?.images_source
                            ) {
                              const { top, left } =
                                addToCompareBtnRef.current?.getBoundingClientRect();

                              dispatch(
                                setCompareProductAnimation({
                                  image:
                                    product?.gallary_images_api?.[0]
                                      ?.images_source,
                                  position: {
                                    top,
                                    left,
                                  },
                                })
                              );
                            }

                            dispatch(addCompares(product?.skus?.[0]?.id));
                          } else {
                            dispatch(removeCompares(product?.skus?.[0]?.id));
                          }
                          dispatch(
                            addCompare({
                              product_sku_id: product?.skus?.[0]?.id,
                              data_type: "product",
                            })
                          );
                        }}
                      >
                        <CompareSvg
                          active={compares.includes(product?.skus?.[0]?.id)}
                        />
                      </div>
                      <span />
                      <div
                        ref={addFavoriteBtnRef}
                        onClick={() => {
                          if (!favorites.includes(product?.id)) {
                            if (
                              product?.gallary_images_api?.[0]?.images_source
                            ) {
                              const { top, left } =
                                addFavoriteBtnRef.current?.getBoundingClientRect();

                              dispatch(
                                setHeartProductAnimation({
                                  image:
                                    product?.gallary_images_api?.[0]
                                      ?.images_source,
                                  position: {
                                    top,
                                    left,
                                  },
                                })
                              );
                            }

                            dispatch(addFavorites(product?.id));
                          } else {
                            dispatch(removeFavorites(product?.id));
                          }
                          dispatch(
                            addWishList({
                              seller_product_id: product?.id,
                            })
                          );
                        }}
                      >
                        <HeartSvg active={favorites.includes(product?.id)} />
                      </div>
                    </div>
                    <div ref={shareDropDownRef} className={styles.shareBtn}>
                      <div
                        className={styles.shareBtnSvg}
                        onClick={() => setShowShareDropDown(!showShareDropDown)}
                      >
                        <ShareSvg />
                      </div>
                      {showShareDropDown ? (
                        <div className={styles.shareDropDown}>
                          <a
                            href={`https://wa.me/?text=${process.env.REACT_APP_SERVER_URL}product${product.id}`}
                            target="_blank" rel="noreferrer"
                          >
                            <svg
                              width="16"
                              height="15"
                              viewBox="0 0 16 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.1441 2.23289C11.7942 0.850024 9.95034 0.0598145 7.97481 0.0598145C3.95791 0.0598145 0.665372 3.35236 0.665372 7.36926C0.665372 8.68627 1.02755 9.93744 1.65313 11.024L0.632446 14.8104L4.48472 13.8226C5.57126 14.3824 6.75657 14.7116 7.97481 14.7116C12.0246 14.7116 15.383 11.4191 15.383 7.40218C15.383 5.42666 14.527 3.61576 13.1441 2.23289ZM7.97481 13.4605C6.88828 13.4605 5.83466 13.1641 4.87983 12.6044L4.68227 12.4727L2.37749 13.0983L3.00308 10.8594L2.83845 10.6289C2.24579 9.64111 1.91654 8.52165 1.91654 7.36926C1.91654 4.04379 4.64935 1.31098 8.00774 1.31098C9.62109 1.31098 11.1357 1.93656 12.288 3.08895C13.4404 4.24134 14.1319 5.75591 14.1319 7.40218C14.1319 10.7277 11.3332 13.4605 7.97481 13.4605ZM11.3332 8.91675C11.1357 8.81798 10.2467 8.38995 10.082 8.32409C9.91741 8.25824 9.78571 8.22532 9.65401 8.42287C9.55523 8.5875 9.19306 9.01553 9.09428 9.14723C8.96258 9.24601 8.8638 9.27893 8.69917 9.18016C7.61264 8.65335 6.9212 8.22532 6.19684 7.00708C5.99929 6.67782 6.39439 6.71075 6.72365 6.01932C6.7895 5.88761 6.75657 5.78884 6.72365 5.69006C6.69072 5.59128 6.29562 4.7023 6.16392 4.34012C5.99929 3.97794 5.86759 4.01086 5.73589 4.01086C5.63711 4.01086 5.50541 4.01086 5.40663 4.01086C5.27493 4.01086 5.07738 4.04379 4.91275 4.24134C4.74812 4.4389 4.28717 4.86693 4.28717 5.75591C4.28717 6.67782 4.91275 7.53388 5.01153 7.66559C5.1103 7.76436 6.29562 9.60819 8.13944 10.3984C9.29183 10.9252 9.75279 10.9581 10.3454 10.8594C10.6747 10.8264 11.3991 10.4313 11.5637 10.0033C11.7283 9.57526 11.7283 9.21308 11.6625 9.14723C11.6295 9.04845 11.4978 9.01553 11.3332 8.91675Z"
                                fill="#282828"
                              ></path>
                            </svg>
                          </a>
                          <a
                            href="https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_SERVER_URL}product14568"
                            target="_blank" rel="noreferrer"
                          >
                            <svg
                              width="12"
                              height="21"
                              viewBox="0 0 12 21"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.6484 11.4167L11.1953 7.82294H7.71874V5.47919C7.71874 4.46356 8.18749 3.52606 9.74999 3.52606H11.3516V0.440125C11.3516 0.440125 9.90624 0.166687 8.53905 0.166687C5.68749 0.166687 3.81249 1.9245 3.81249 5.0495V7.82294H0.609366V11.4167H3.81249V20.1667H7.71874V11.4167H10.6484Z"
                                fill="#282828"
                              ></path>
                            </svg>
                          </a>
                          <a
                            href="https://telegram.me/share/url?url=${process.env.REACT_APP_SERVER_URL}product14568&amp;text=Default+share+text"
                            target="_blank" rel="noreferrer"
                          >
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.49028 0.0401592C3.97949 0.0401592 0.324771 3.72781 0.324771 8.20566C0.324771 12.7164 3.97949 16.3712 8.49028 16.3712C12.9681 16.3712 16.6558 12.7164 16.6558 8.20566C16.6558 3.72781 12.9681 0.0401592 8.49028 0.0401592ZM12.2438 5.60456C12.145 6.88865 11.6182 10.0495 11.3219 11.4653C11.2231 12.0909 10.9926 12.2884 10.7621 12.3213C10.3012 12.3543 9.93899 11.9921 9.47804 11.6958C8.75368 11.2348 8.35857 10.9385 7.66714 10.4775C6.844 9.95071 7.37081 9.65438 7.83177 9.19343C7.96347 9.06172 10.0378 7.15205 10.0707 6.98742C10.1036 6.9545 10.1036 6.88865 10.0378 6.8228C10.0048 6.78987 9.93899 6.8228 9.87314 6.8228C9.80729 6.8228 8.6549 7.58008 6.4489 9.09465C6.11965 9.32513 5.82332 9.4239 5.55991 9.4239C5.26358 9.4239 4.70385 9.25928 4.27582 9.12757C3.78194 8.96295 3.35391 8.86417 3.38684 8.60077C3.41976 8.43614 3.61731 8.30444 4.01242 8.13981C6.38305 7.0862 7.96347 6.42769 8.75368 6.09844C11.0255 5.1436 11.5194 4.97897 11.8157 4.97897C11.8816 4.97897 12.0462 4.97897 12.145 5.07775C12.1779 5.11067 12.2438 5.20945 12.2438 5.2753C12.2767 5.407 12.2767 5.50578 12.2438 5.60456Z"
                                fill="#282828"
                              ></path>
                            </svg>
                          </a>
                          <a
                            href="https://twitter.com/intent/tweet?text=${process.env.REACT_APP_SERVER_URL}product14568"
                            target="_blank" rel="noreferrer"
                          >
                            <svg
                              width="18"
                              height="15"
                              viewBox="0 0 18 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M15.7079 4.25411C15.7079 4.41874 15.7079 4.55044 15.7079 4.71507C15.7079 9.2917 12.2508 14.5268 5.89615 14.5268C3.92062 14.5268 2.10972 13.9671 0.595154 12.9793C0.858557 13.0123 1.12196 13.0452 1.41829 13.0452C3.03163 13.0452 4.51328 12.4855 5.69859 11.5636C4.18402 11.5306 2.89993 10.5429 2.4719 9.16C2.70238 9.19292 2.89993 9.22585 3.13041 9.22585C3.42674 9.22585 3.75599 9.16 4.0194 9.09415C2.43898 8.76489 1.25366 7.38203 1.25366 5.70283V5.6699C1.71462 5.93331 2.27435 6.06501 2.83408 6.09793C1.87925 5.47235 1.28659 4.41874 1.28659 3.23342C1.28659 2.57491 1.45121 1.98226 1.74754 1.48838C3.45967 3.56268 6.02785 4.94555 8.89236 5.11017C8.82651 4.84677 8.79358 4.58337 8.79358 4.31996C8.79358 2.41029 10.3411 0.862793 12.2508 0.862793C13.2385 0.862793 14.1275 1.2579 14.786 1.94933C15.5433 1.7847 16.3006 1.48838 16.9591 1.09327C16.6957 1.91641 16.1689 2.57491 15.4445 3.00295C16.136 2.93709 16.8274 2.73954 17.42 2.47614C16.9591 3.16757 16.3664 3.76023 15.7079 4.25411Z"
                                fill="#282828"
                              ></path>
                            </svg>
                          </a>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <h1 className={styles.prodName}>
                  {product?.brand.name + " " + product?.product_name}
                </h1>
                <div className={styles.skuAndStikcer}>
                  <div className={styles.skuContainer}>
                    <span className={styles.nameBoldGray}>SKU</span>
                    <span className={styles.blackSp}>
                      00{product?.li_product_id}
                    </span>
                  </div>
                  {product.sticker[0]?.["image_" + currentLanguage] && (
                    <img
                      className={styles.stickerImg}
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        product.sticker[0]?.["image_" + currentLanguage]
                      }
                    />
                  )}
                </div>

                <div className={styles.dashLine} />
                <div className={`${styles.row1} ${styles.brandBlock}`}>
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/brand/${product.brand.slug}/category`}
                  >
                    <img
                      className={styles.brandLogo}
                      src={
                        checkBase64(product?.brand.logo)
                          ? "data:image/png;base64," + product?.brand.logo
                          : process.env.REACT_APP_STORAGE_URL +
                            product?.brand.logo
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../assets/defaultImg.png");
                      }}
                      alt={product?.brand.name}
                    />
                  </Link>

                  <div className={styles.guaranteeBlock}>
                    <div className={styles.isAvailable}>
                      <span>{lang[currentLanguage].in_stock}</span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8ZM11.5938 6.625C11.9375 6.28125 11.9375 5.75 11.5938 5.40625C11.25 5.0625 10.7188 5.0625 10.375 5.40625L7 8.78125L5.59375 7.40625C5.25 7.0625 4.71875 7.0625 4.375 7.40625C4.03125 7.75 4.03125 8.28125 4.375 8.625L6.375 10.625C6.71875 10.9688 7.25 10.9688 7.59375 10.625L11.5938 6.625Z"
                          fill="#159859"
                        ></path>
                      </svg>
                    </div>
                    {product?.gift_images?.length ? (
                      <div className={styles.giftBox}>
                        <GiftSvg />
                        {/* <div className={styles.giftHovered}>
                          <div className={styles.giftHoveredMain}>
                            <Link
                              to={
                                (currentLanguage !== "hy"
                                  ? "/" + currentLanguage
                                  : "") +
                                "/Product/" +
                                product?.gift_images?.[0]?.product_id
                              }
                            >
                              <img
                                src={
                                  process.env.REACT_APP_STORAGE_URL +
                                  product?.gift_images?.[0]?.image
                                }
                                className={styles.giftHoveredImg}
                                alt="gift"
                                onError={({ currentTarget }) => {
                                  currentTarget.src = "";
                                }}
                              />
                            </Link>
                          </div>
                        </div> */}
                      </div>
                    ) : null}
                    {Object.keys(product?.guaranty || {}).length ? (
                      <div className={styles.guarantee}>
                        <span>{lang[currentLanguage].guaranty}</span>
                        <span>{product?.guaranty[currentLanguage]}</span>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className={styles.row1}>
                  <div className={styles.priceAverageBlock}>
                    {product?.online_price && product?.online_selling_price ? (
                      <div>
                        <span className={styles.nameBoldGray}>
                          {lang[currentLanguage].average_price}
                        </span>{" "}
                        <span className={styles.oldPriceBlock}>
                          <span className={styles.oldPrice}>
                            {UseCalcPrice(
                              product?.skus?.[0].selling_price,
                              currentCurrency
                            )}
                          </span>
                          <span className={styles.oldPriceLine} />
                        </span>
                      </div>
                    ) : null}
                    {product?.average_price ? (
                      <div>
                        <span className={styles.nameBoldGray}>
                          {lang[currentLanguage].average_price}
                        </span>{" "}
                        <span className={styles.oldPriceBlock}>
                          <span className={styles.oldPrice}>
                            {UseCalcPrice(
                              product?.average_price,
                              currentCurrency
                            )}
                          </span>
                          <span className={styles.oldPriceLine} />
                        </span>
                      </div>
                    ) : null}
                    <div className={styles.priceBlock}>
                      <span className={styles.nameBoldGray}>
                        {lang[currentLanguage].price}
                      </span>
                      {product?.skus?.[0].promo_price ||
                      (product?.online_price &&
                        product?.online_selling_price) ? (
                        <>
                          <span className={styles.newPrice}>
                            {UseCalcPrice(
                              (product?.skus?.[0].promo_price ||
                                (product?.online_price &&
                                  product?.online_selling_price)) -
                                ((!installingBtnBlockActive &&
                                  product?.installing_price) ||
                                  0),
                              currentCurrency
                            )}
                          </span>
                        </>
                      ) : (
                        <span className={styles.price}>
                          {UseCalcPrice(
                            product?.skus?.[0].selling_price -
                              ((!installingBtnBlockActive &&
                                product?.installing_price) ||
                                0),
                            currentCurrency
                          )}
                        </span>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "10px",
                      }}
                    >
                      {product?.cashback ? (
                        <div className={styles.cashback}>
                          <span>Cashback </span>
                          <span>
                            {UseCalcPrice(product?.cashback, currentCurrency)}
                          </span>
                        </div>
                      ) : null}

                      {new Date() <= new Date(product?.date_discount_li_end) &&
                      (product?.skus?.[0].promo_price ||
                        (product?.online_price &&
                          product?.online_selling_price)) ? (
                        <span>
                          {lang[currentLanguage].discount_available
                            .replace(":star", product?.date_discount_li_start)
                            .replace(":ende", product?.date_discount_li_end)}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                {colors?.length > 1 && <div className={styles.colors}>
                <span>{langData?.product?.color?.[currentLanguage]}</span>
                 { colors?.length > 1 &&
                   colors?.map((item,index) => (
                    <div className={styles.colorCircleContainer}>
                      <span
                        onClick={index ? () => navigate(`/Product/${item?.product?.product_id}`) : null}
                        className={styles.colorCirlce}
                        style={{background:item?.colors?.value,cursor:index ? 'pointer' : 'unset'}}
                        ></span>
                      </div>
                  ))
                 }
                </div>}
                {product?.gift_images?.length ? (
                  <>
                    {/* <span className={styles.nameBoldGray}>
                      {product?.gift_images?.length > 1
                        ? choose_your_gift[currentLanguage]
                        : lang[currentLanguage].by_gif}
                    </span> */}
                    <div className={styles.giftBlock}>
                      {product?.gift_images?.map((prod) => (
                        <Link
                          to={"/Product/" + prod.product_id}
                          className={
                            prod?.product_id == choosenGift
                              ? styles.giftImg + " " + styles.giftImgChoosen
                              : styles.giftImg
                          }
                        >
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + prod?.image
                            }
                          />
                          <span>{prod?.product_name}</span>
                        </Link>
                      ))}
                    </div>
                  </>
                ) : null}
          
                {product?.installing_price && product?.categories?.[0]?.installation !== 0 ? (
                  <div>
                    <div className={styles.installingPriceBlock}>
                      <div
                        onClick={() =>
                          setInstallingBtnBlockActive(!installingBtnBlockActive)
                        }
                        className={`${styles.installingBtnBlock} ${
                          installingBtnBlockActive &&
                          styles.installingBtnBlockActive
                        }`}
                      >
                        <div className={styles.installingBtn}></div>
                      </div>
                      <span className={styles.nameBoldGray}>
                        {lang[currentLanguage].installing}
                      </span>
                      <span>
                        {UseCalcPrice(
                          product?.installing_price,
                          currentCurrency
                        )}
                      </span>
                    </div>
                    {/* <div className={styles.dashLine} /> */}
                  </div>
                ) : null}
                 {product?.categories?.[0]?.["category_descr_" + currentLanguage]  ? ( 
                  <div>
                    <p>{product?.categories?.[0]?.["category_descr_" + currentLanguage]}</p>
                  </div>
                 ) : null}
                {(((product?.has_cash_price && product?.cash_price) && product?.cash_price) ||  product?.skus?.[0].promo_price ||
                          (product?.online_price &&
                            product?.online_selling_price) ||
                          product?.skus?.[0].selling_price) >= 26000 &&
                          <>
                          <div className={styles.dashLine} />
                           <div className={styles.creditBlock}>
                  <div className={styles.priceDateColumn}>
                    <span className={styles.creditText}
                    style={{fontWeight:product?.has_cash_price && product?.cash_price ? "bold" : ''}}
                    >
                      {
                        product?.has_cash_price && product?.cash_price ?
                        langData.product.price[currentLanguage]
                        :
                        lang[currentLanguage].Cash
                      }
                    </span>
                    <span className={styles.creditPrice}>
                      {UseCalcPrice(
                      ((product?.has_cash_price && product?.cash_price) && product?.cash_price) ||  product?.skus?.[0].promo_price ||
                          (product?.online_price &&
                            product?.online_selling_price) ||
                          product?.skus?.[0].selling_price,
                        currentCurrency
                      )}
                    </span>
                  </div>
                  {(product?.skus?.[0].promo_price ||
                    (product?.online_price && product?.online_selling_price) ||
                    product?.skus?.[0].selling_price) > 26000 ? (
                    <>
                      <div className={styles.creditLine} />
                      <div className={styles.priceDateColumn}>
                        <span className={styles.creditText}>
                          24 {lang[currentLanguage].month}
                        </span>
                        <span className={styles.creditPrice}>
                          {UseCalcPrice(
                            credit24Month(
                              product?.skus?.[0].promo_price ||
                                (product?.online_price &&
                                  product?.online_selling_price) ||
                                product?.skus?.[0].selling_price
                            ),
                            currentCurrency
                          )}
                        </span>
                      </div>
                      <div className={styles.creditLine} />
                      <div className={styles.priceDateColumn}>
                        <span className={styles.creditText}>
                          36 {lang[currentLanguage].month}
                        </span>
                        <span className={styles.creditPrice}>
                          {UseCalcPrice(
                            credit36Month(
                              product?.skus?.[0].promo_price ||
                                (product?.online_price &&
                                  product?.online_selling_price) ||
                                product?.skus?.[0].selling_price
                            ),
                            currentCurrency
                          )}
                        </span>
                      </div>
                    </>
                  ) : null}

                  {(product?.skus?.[0].promo_price ||
                    (product?.online_price && product?.online_selling_price) ||
                    product?.skus?.[0].selling_price) > 26000 ? (
                    <div
                      className={styles.buyCreditBtn}
                      onClick={() => setShowCreditModal("credit")}
                    >
                      <span>{lang[currentLanguage].buy_on_credit}</span>
                    </div>
                  ) : null}
                          </div>
                          </>
                          }
                <div className={styles.dashLine} />
                <div className={styles.countAddCartBuyNowBlock}>
                  <div className={styles.countBlock}>
                    <div
                      className={styles.countBtn}
                      onClick={() => count > 1 && setCount(count - 1)}
                    >
                      <svg
                        width="14"
                        height="2"
                        viewBox="0 0 14 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.71875 1.07764H13.4372"
                          stroke="#282828"
                        ></path>
                      </svg>
                    </div>
                    <span className={styles.countInput}>{count}</span>
                    <div
                      className={styles.countBtn}
                      onClick={() => setCount(count + 1)}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.563477 7.07773H13.2819M6.9227 0.718506L6.9227 13.437"
                          stroke="#282828"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div
                    ref={addToCartBtnRef}
                    className={`${styles.buyBtn} ${styles.addCartBtn}`}
                    onClick={() => {
                      if (product?.gallary_images_api?.[0]?.images_source) {
                        const { top, left } =
                          addToCartBtnRef.current?.getBoundingClientRect();

                        dispatch(
                          setShopCartProductAnimation({
                            image:
                              product?.gallary_images_api?.[0]?.images_source,
                            position: {
                              top,
                              left,
                            },
                          })
                        );
                      }
                      dispatch(
                        addShopCartCount({
                          price:
                            +(
                              (product?.skus?.[0].promo_price ||
                                +(
                                  product?.online_price &&
                                  product?.online_selling_price
                                ) ||
                                product?.skus?.[0].selling_price) -
                              ((!installingBtnBlockActive &&
                                product?.installing_price) ||
                                0)
                            ) * count,
                          count: count,
                        })
                      );
                      dispatch(
                        addCardStore({
                          gift_product_id: choosenGift,
                          price:
                            +product?.skus?.[0].promo_price ||
                            +(
                              product?.online_price &&
                              product?.online_selling_price
                            ) ||
                            +product?.skus?.[0].selling_price,
                          qty: count,
                          product_id: product?.seller_product_sku_id,
                          seller_id: 1,
                          shipping_method_id: 0,
                          type: "product",
                          is_buy_now: "no",
                          installing: installingBtnBlockActive,
                          installing_count: +installingBtnBlockActive,
                          installing_qty: +installingBtnBlockActive,
                        })
                      );
                    }}
                  >
                    <ShopCartSvg className={styles.cardBtnSvg} />
                    <span>{lang[currentLanguage].add}</span>
                  </div>
                  <div
                    className={`${styles.buyBtn} ${styles.buyItNow}`}
                    onClick={() => setShowCreditModal("buyNow")}
                  >
                    <span>{lang[currentLanguage].buy_now}</span>
                  </div>
                </div>
                <div className={styles.dashLine} />
                <div className={styles.productInfoCredit}>
                  <div onClick={() => setShowInfoModal("online")}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4647_58906)">
                        <path
                          d="M0.960734 9.67499L2.41152 10.7414L2.18886 11.3133C2.16658 11.3708 2.15588 11.4322 2.15737 11.4939C2.15886 11.5556 2.17252 11.6164 2.19756 11.6728C2.22261 11.7292 2.25854 11.7801 2.3033 11.8225C2.34806 11.865 2.40076 11.8982 2.45839 11.9203L5.72323 13.1742L7.38261 14.3906C7.46228 14.4506 7.55945 14.4827 7.65917 14.482H7.72948C7.79049 14.4725 7.84901 14.4511 7.9017 14.4189C7.95439 14.3867 8.00021 14.3445 8.03652 14.2945L8.16776 14.1187L9.89745 14.7844C9.95135 14.8048 10.0086 14.8151 10.0662 14.8148C10.1315 14.8145 10.1961 14.801 10.256 14.775C10.3125 14.7501 10.3636 14.7143 10.4062 14.6697C10.4488 14.625 10.4822 14.5724 10.5045 14.5148L13.3826 7.01483L14.1396 5.98358C14.184 5.92341 14.2133 5.85352 14.2251 5.77972C14.2369 5.70593 14.2309 5.63037 14.2076 5.55937L14.0318 5.02968L12.656 0.937491C12.6271 0.845245 12.5704 0.764199 12.4936 0.705458C12.4168 0.646717 12.3237 0.613153 12.2271 0.609366L7.3498 0.517959C7.27499 0.514002 7.20039 0.528878 7.13283 0.561227C7.06526 0.593576 7.0069 0.642362 6.96308 0.703116L0.869328 9.01405C0.83154 9.0633 0.80391 9.11956 0.78804 9.17957C0.77217 9.23958 0.768377 9.30214 0.77688 9.36363C0.785384 9.42511 0.806015 9.4843 0.837576 9.53775C0.869137 9.5912 0.911 9.63785 0.960734 9.67499ZM9.79667 13.7344L8.74198 13.3289L10.1201 11.4539L11.2779 9.87655L9.79667 13.7344ZM7.5748 1.46015L11.8779 1.53983L13.242 5.62499L8.11855 12.5976L7.62401 13.2703L7.55839 13.357L6.23652 12.3867L4.24433 10.9242L1.90058 9.20155L7.5748 1.46015Z"
                          fill="#E31335"
                        ></path>
                        <path
                          d="M8.74198 13.3289L9.79667 13.7344L11.2779 9.87655L10.1201 11.4539L8.74198 13.3289Z"
                          fill="#E31335"
                        ></path>
                        <path
                          d="M8.74219 13.3288L9.79687 13.7343L11.2781 9.87646L10.1203 11.4538L8.74219 13.3288Z"
                          fill="#282828"
                        ></path>
                        <path
                          d="M9.60951 4.70386C9.87187 4.89636 10.1929 4.99192 10.5178 4.97424C10.8427 4.95656 11.1515 4.82674 11.3914 4.60691C11.6313 4.38707 11.7876 4.09083 11.8336 3.76868C11.8795 3.44653 11.8123 3.11841 11.6435 2.84026C11.4746 2.5621 11.2145 2.35112 10.9074 2.24328C10.6004 2.13544 10.2655 2.13742 9.95975 2.24888C9.65402 2.36033 9.39641 2.57437 9.23083 2.8545C9.06524 3.13464 9.00194 3.46353 9.0517 3.78511C9.10865 4.15314 9.30925 4.48355 9.60951 4.70386ZM10.0642 3.29761C10.1078 3.23789 10.165 3.18935 10.231 3.15595C10.297 3.12254 10.3699 3.10523 10.4439 3.10542C10.5424 3.10556 10.6383 3.13673 10.7181 3.19449C10.7932 3.24896 10.8504 3.32448 10.8826 3.4115C10.9147 3.49852 10.9203 3.59312 10.8987 3.68333C10.877 3.77353 10.8291 3.85528 10.7609 3.91823C10.6928 3.98117 10.6075 4.02249 10.5159 4.03694C10.4242 4.05139 10.3304 4.03833 10.2462 3.99941C10.162 3.96048 10.0912 3.89745 10.0429 3.81829C9.99449 3.73913 9.9707 3.6474 9.9745 3.55471C9.9783 3.46202 10.0095 3.37255 10.0642 3.29761Z"
                          fill="#E31335"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_4647_58906">
                          <rect width="15" height="15" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    <span className={styles.nameBoldGray}>
                      {lang[currentLanguage].online_price_info}
                    </span>
                  </div>
                  <div onClick={() => setShowInfoModal("credit")}>
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_4647_58907)">
                        <path
                          d="M14.3119 13.6331H0.681688C0.3051 13.6331 0 13.9382 0 14.3151C0 14.6913 0.3051 14.9964 0.681688 14.9964H14.3116C14.6882 14.9964 14.9933 14.6913 14.9933 14.3151C14.993 13.9382 14.6879 13.6331 14.3119 13.6331Z"
                          fill="#E31335"
                        ></path>
                        <path
                          d="M1.53325 11.8609C1.15666 11.8609 0.851562 12.166 0.851562 12.5425C0.851562 12.9191 1.15666 13.2246 1.53325 13.2246H13.4596C13.8362 13.2246 14.1413 12.9191 14.1413 12.5425C14.1413 12.166 13.8362 11.8609 13.4596 11.8609H13.2895V5.72695H13.4596C13.6482 5.72695 13.8001 5.5744 13.8001 5.38642C13.8001 5.19845 13.6476 5.0459 13.4596 5.0459H1.53325C1.34496 5.0459 1.19273 5.19845 1.19273 5.38642C1.19273 5.5744 1.34528 5.72695 1.53325 5.72695H1.70367V11.8605L1.53325 11.8609ZM11.9261 5.72695V11.8605H9.88169V5.72695H11.9261ZM8.51831 5.72695V11.8605H6.47389V5.72695H8.51831ZM3.06641 5.72695H5.11083V11.8605H3.06641V5.72695Z"
                          fill="#282828"
                        ></path>
                        <path
                          d="M0.681701 4.4332H14.3116C14.3139 4.4332 14.3164 4.4332 14.3183 4.4332C14.6952 4.4332 15 4.1281 15 3.75151C15 3.45215 14.8073 3.19843 14.5395 3.10652L7.77687 0.0635013C7.59911 -0.0162843 7.39581 -0.0162843 7.21773 0.0635013L0.402132 3.13014C0.10884 3.26226 -0.0523264 3.58077 0.0153318 3.8948C0.0826708 4.20884 0.360324 4.4332 0.681701 4.4332Z"
                          fill="#E31335"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_4647_58907">
                          <rect width="15" height="15" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    <span className={styles.nameBoldGray}>
                      {lang[currentLanguage].credit_info_product}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <LoadContent containerClassName={styles.infoBlockLoader} />
            )}
          </div>
        </div>
        <div className={styles.productLeftGeneral}>
          {Object.keys(product || {}).length && product?.variations?.length ? (
            <>
              <span className={styles.secondTitle}>
                {lang[currentLanguage].character}
              </span>
              <div className={styles.infoProd}>
                {product?.variations.map((item, index) => {
                  if (index > 8 && !showMoreAtributes) return null;
                  if (
                    item?.attribute?.["name_" + currentLanguage] &&
                    item?.attribute_value?.["value_" + currentLanguage]
                  )
                    return (
                      <div className={styles.infoRow}>
                        <span className={styles.infoRowKey}>
                          {item?.attribute?.["name_" + currentLanguage]}
                        </span>
                        <div className={styles.infoRowLine}></div>
                        <span className={styles.infoRowValue}>
                          {item?.attribute_value?.color?.[
                            "name_" + currentLanguage
                          ] ||
                            item?.attribute_value?.["value_" + currentLanguage]}
                        </span>
                      </div>
                    );
                })}
                {product?.variations.length > 8 ? (
                  <span
                    onClick={() => setShowMoreAtributes(!showMoreAtributes)}
                    className={styles.seeMoreVariation}
                  >
                    {showMoreAtributes
                      ? lang[currentLanguage].to_close
                      : lang[currentLanguage].to_learn_more}
                  </span>
                ) : null}
              </div>
            </>
          ) : null}

        
        </div>
      </div>
      {similarProducts?.similarProducts?.length ? (
        <ProductsWithSlide
          title={lang[currentLanguage].related_products}
          productsData={similarProducts?.similarProducts}
        />
      ) : null}

      <RelatedProducts />
        <div className="wrapper productCard">
          {Object.keys(product || {}).length && product?.variations?.length ? (
              <h3 className={styles.title}>
                {lang[currentLanguage].COMPARE}
              </h3>
            ) : null}
            {Object.keys(product || {}).length && product?.variations?.length ? (
              <ProductPageCompare />
            ) : null}
        </div>
      <WorkInformations />
      {/* {recentProducts.length ? (
        <ProductsWithSlide
          title={lang[currentLanguage].hew_you_watched}
          productsData={recentProducts}
        />
      ) : null} */}
      <StikckyFooter
        count={count}
        installingBtnBlockActive={installingBtnBlockActive}
        showBottomSticky={showBottomSticky}
      />
      <CreditModal
        product={product}
        choosenGift={choosenGift}
        modalType={showCreditModal}
        closeModal={() => {
          setShowCreditModal(false);
        }}
        propsPrice={
          product?.skus?.[0].promo_price
            ? product?.skus?.[0].promo_price -
              ((!installingBtnBlockActive && product?.installing_price) || 0)
            : product?.online_price && product?.online_selling_price
            ? (product?.online_price && product?.online_selling_price) -
              ((!installingBtnBlockActive && product?.installing_price) || 0)
            : product?.skus?.[0].selling_price -
              ((!installingBtnBlockActive && product?.installing_price) || 0)
        }
        qty={count}
        installingIsActive={installingBtnBlockActive}
        haveInstalling={!!product?.installing_price}
      />
      <InfoModal
        modalType={showInfoModal}
        closeModal={() => setShowInfoModal(null)}
      />
    </>
  );
}
