import React, { memo, useEffect } from "react";
import "./Stories.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { getStoriesRequest } from "./getStoriesSlice";
import LoadContent from "components/LoadContent";

const StoriesComponent = ({
  setSelectedStoryIndex,
  setIsOpen,
  handleFullScreenToggle,
}) => {
  const dispatch = useDispatch();
  const { get_stories, imagesPath, loading } = useSelector(
    (state) => state.getStoriesSlice
  );

  useEffect(() => {
    dispatch(getStoriesRequest());
  }, [dispatch]);

  const handleStoryClick = (index) => {
    setSelectedStoryIndex(index);
    setIsOpen(true);
    handleFullScreenToggle();
  };

  return (
    <div className="wrapper">
      <Swiper
        navigation={window.innerWidth > 425 ? true : false}
        slidesPerView={"auto"}
        spaceBetween={window.innerWidth > 425 ? 30 : 20}
        modules={[Navigation]}
        loop={false}
        className="stories__wrapper">
        {loading ? (
          <div
            style={{ display: "flex", gap: window.innerWidth > 425 ? 30 : 20 }}>
            {Array.from({ length: 9 }).map((_, ind) => (
              <LoadContent
                containerClassName={"StoryLoader"}
                key={ind}
              />
            ))}
          </div>
        ) : (
          get_stories.slice(0, 16)?.map((item, index) => (
            <SwiperSlide
              key={index}
              onClick={() => handleStoryClick(index)}>
              <img
                src={imagesPath + "/" + item.main_photo}
                alt=""
              />
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export const Stories = memo(StoriesComponent);
