import {
  createBrowserRouter,
  Outlet,
  useLocation,
  useParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  AboutUs,
  Blog,
  Credit,
  Home,
  Jobs,
  ProductPage,
  Catalog,
  Delivery,
  CartContent,
} from "screens";
import Header from "components/Header";
import Footer from "components/Footer";
import MobileFooter from "components/MobileFooter";
import LoginModal from "components/LoginModal";
import AddToCartAnimation from "components/AddToCartAnimation";
import AddToFavoriteAnimation from "components/AddToFavoriteAnimation";
import AddToCompareAnimation from "components/AddToCompareAnimation";
import SubscribeModal from "components/SubscribeModal";
import React, { useEffect, useState } from "react";
import { getTranlations, postLang, setCurrentLanguage } from "store/MainSlice";
import useScrollToTop from "helpers/useScrollToTop";
import Sales from "screens/Sales";
import Toshiba from "screens/Toshiba/index";
import Samsung from "screens/Samsung/index";
import Hisense from "screens/Hisense/index";
import SearchNull from "screens/SearchNull";
import BlogItem from "screens/BlogItem";
import FavoritePage from "screens/FavoritePage";
import SearchResult from "screens/SearchResult";
import BrandSeeAll from "screens/BrandSeeAll";
import { MetaTags } from "react-meta-tags";
import SalesProductDetails from "screens/SalesProductDetails/index";
import Payment from "screens/Payment/index";
import PrivacyPolicy from "screens/PrivacyPolicy/index";
import Service from "screens/Service/index";
import Order from "screens/Order/index";
import FrequentQuestions from "screens/FrequentQuestions/index";
import Vikas from "screens/Vikas/index";
import ComparePage from "screens/ComparePage/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReviewModal from "components/ReviewModal";
import Contacts from "screens/Contacts/index";
import PersonalPage from "screens/USERPAGE/PersonalPage";
import PersonalInformation from "screens/USERPAGE/PersonalInformation";
import MyAddresses from "screens/USERPAGE/MyAddresses";
import OrderHistory from "screens/USERPAGE/OrderHistory";
import VirtualGoods from "screens/USERPAGE/VirtualGoods";
import Favorites from "screens/USERPAGE/UserPageFavorites";
import Brand from "screens/SearchResult/components/Category/index";
import Category from "screens/Category";

const Layout = () => {
  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const { lang } = useParams();
  const lang2 = document.querySelector("html[lang]")?.attributes?.lang?.value;
  const location = useLocation();
  const dispatch = useDispatch();
  const [showReviewModal, setShowReviewModal] = useState(false);

  // const onOpenModal = () => {
  //   if (!sessionStorage.getItem("review_modal")) {
  //     setTimeout(() => {
  //       sessionStorage.setItem("review_modal", "11111111");
  //       setShowReviewModal(true);
  //     }, 120000);
  //   } else {
  //     setTimeout(() => {
  //       setShowReviewModal(true);
  //     }, 6 * 60 * 60 * 1000);
  //   }
  // };

  // useEffect(() => {
  //   onOpenModal();
  // }, [showReviewModal]);
  useEffect(() => {
    if (lang === "hy" || lang === "en" || lang === "ru") {
      dispatch(postLang(lang));
    }
    if (lang === "am" || lang === "hy" || !lang) {
      dispatch(setCurrentLanguage("hy"));
      return;
    } else if (lang === "en" || lang === "ru") {
      dispatch(setCurrentLanguage(lang));
    } else if (lang2) {
      dispatch(setCurrentLanguage(lang2));
    }
  }, [dispatch, lang, lang2]);

  const clearCache = () => {
    if (typeof window !== "undefined" && "caches" in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };
  useEffect(() => {
    // clearCache();
    // window.scrollTo(0, 0);
    dispatch(getTranlations());
  }, [dispatch]);
  //  useScrollToTop();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="light"
        icon={false}
        style={{}}
        progressStyle={{
          backgroundColor: "#d10000",
          zIndex: 999999999999999,
        }}
      />
      <MetaTags>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {/* 
          <meta name="s_d" content="1fmbPcd1pf8RZDlA0YD6dZGBYl4L9g6C0f2FqaeT" />
          <meta
            name="_token"
            content="2rwrXfAe2tauo4ye5LFhIg7IF52NrqANgGqu4Vhg"
          /> */}

        <meta
          name="compare_d"
          content=""></meta>
      </MetaTags>
      <style jsx>
        {currentLanguage == "ru" &&
          `* {
                font-family: arial !important;
              }`}
      </style>
      <SubscribeModal />

      <LoginModal />
      {location.pathname !== "/" + [currentLanguage] + "/cart" &&
        location.pathname !== "/cart" && (
          <>
            <AddToCartAnimation />
            <AddToFavoriteAnimation />
            <AddToCompareAnimation />
            <Header />
          </>
        )}
      <Outlet />
      <Footer />
      {window.innerWidth <= 992 && <MobileFooter />}
      {/* {showReviewModal && (
        <ReviewModal
          showReviewModal={showReviewModal}
          setShowReviewModal={setShowReviewModal}
        />
      )} */}
    </>
  );
};

const routes = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/:lang?",
        element: <Home />,
      },
      {
        path: "/:lang?/blog",
        element: <Blog />,
      },
      {
        path: "/:lang?/blog/:slug",
        element: <BlogItem />,
      },
      {
        path: "/:lang?/about-us",
        element: <AboutUs />,
      },
      {
        path: "/:lang?/credit",
        element: <Credit />,
      },
      {
        path: "/:lang?/Product/:productId",
        element: <ProductPage />,
      },
      {
        path: "/:lang?/catalog/:slug?",
        element: <Catalog />,
      },
      {
        path: "/:lang?/cart",
        element: <CartContent />,
      },
      {
        path: "/:lang?/search/:params?",
        element: <SearchResult />,
      },
      {
        path: "/:lang?/pages/:slug",
        element: <SearchResult />,
      },
      {
        path: "/:lang?/sales",
        element: <Sales />,
      },
      {
        path: "/:lang?/brand/hisense",
        element: <Hisense />,
      },
      {
        path: "/:lang?/brand/toshiba",
        element: <Toshiba />,
      },
      {
        path: "/:lang?/brand/samsung",
        element: <Samsung />,
      },
      {
        path: "/:lang?/brand/:name?",
        element: <Vikas />,
      },
      {
        path: "/:lang?/brandSeeAll",
        element: <BrandSeeAll />,
      },
      {
        path: "/:lang?/delivery",
        element: <Delivery />,
      },
      {
        path: "/:lang?/compare",
        element: <ComparePage />,
      },
      {
        path: "/:lang?/favorite",
        element: <FavoritePage />,
      },
      {
        path: "/:lang?/job",
        element: <Jobs />,
      },
      {
        path: "/:lang?/search-null",
        element: <SearchNull />,
      },
      {
        path: "/:lang?/sale/:id/:view",
        element: <SalesProductDetails />,
      },
      {
        path: "/:lang?/contacts",
        element: <Contacts />,
      },
      {
        path: "/:lang?/payment",
        element: <Payment />,
      },
      {
        path: "/:lang?/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/:lang?/service",
        element: <Service />,
      },
      {
        path: "/:lang?/order",
        element: <Order />,
      },
      {
        path: "/:lang?/faq",
        element: <FrequentQuestions />,
      },
      {
        path: "/:lang?/profile/dashboard",
        element: <PersonalPage />,
      },
      {
        path: "/:lang?/category/:categoryId",
        element: <Brand />,
      },
      {
        path: "/:lang?/brand/:brandId/category",
        element: <Brand />,
      },
      {
        path: "/:lang?/category/filter/:categoryId",
        element: <Category />,
      },
      {
        path: "/:lang?/profile/personal-info",
        element: <PersonalInformation />,
      },
      {
        path: "/:lang?/profile/my-addresses",
        element: <MyAddresses />,
      },
      {
        path: "/:lang?/profile/order-history",
        element: <OrderHistory />,
      },
      {
        path: "/:lang?/profile/virtual-products",
        element: <VirtualGoods />,
      },
      {
        path: "/:lang?/profile/my-wishlist",
        element: <Favorites />,
      },
    ],
  },
]);

export default routes;
