import React, { useEffect, useMemo } from "react";
import styles from "./style.module.css";
import FromToPage from "../../components/FromToPage";
import BanerSlider from "../../components/BanerSlider";
import { useDispatch, useSelector } from "react-redux";
import ProductsWithSlide from "../../components/ProductsWithSlide";
import CategoryBlock from "../Hisense/categoryBlock";
import { getBrandPageInfo } from "../../store/MainSlice";
import langData from "../../lang/data";
import LoadContent from "../../components/LoadContent";
import { Link } from "react-router-dom";

const Samsung = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBrandPageInfo("samsung"));
  }, []);
  const { brand, currentLanguage } = useSelector(({ main }) => main);

  const pages = useMemo(
    () => [{ label: brand?.brand.name, value: `/samsung`, active: true }],
    [brand?.brand.name]
  );

 

  return (
    <>
      <div className="wrapper">
        <FromToPage pages={pages} />
        <BanerSlider
          bannerSliders={brand?.slider1}
          imgStyle={{ maxHeight: "none" }}
        />
        <div className={styles.InfoAboutPage}>
          {!brand ? (
            <LoadContent containerClassName={styles.LoadContentGeneralBanner} />
          ) : (
            <element
              dangerouslySetInnerHTML={{
                __html: brand?.info?.["text_1_" + currentLanguage],
              }}
            ></element>
          )}
        </div>
        <div className={styles.twoBanersBlck}>
          <a href={brand?.banners?.banner_1_href}>
            {!brand ? (
              <LoadContent
                containerClassName={styles.LoadContentTwoBanersBlck}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_1_" + currentLanguage]
                }
              />
            )}
          </a>
          <a href={brand?.banners?.banner_2_href}>
            {!brand ? (
              <LoadContent
                containerClassName={styles.LoadContentTwoBanersBlck}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_2_" + currentLanguage]
                }
              />
            )}
          </a>
        </div>
        <a href={brand?.banners?.banner_3_href}>
          <div className={styles.generalBanner}>
            {!brand ? (
              <LoadContent
                containerClassName={styles.LoadContentGeneralBanner}
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.banners["banner_3_" + currentLanguage]
                }
              />
            )}
          </div>
        </a>
      </div>

      <div className="wrapper">
        <div className={styles.titleBlck}>
          <h1>{langData.titles.BestDealProduct[currentLanguage]}</h1>
          <Link
            url={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/brand/samsung/category`}
            className={styles.topPicksProductBtn}
          >
            {langData.buttons.view_all[currentLanguage]}
          </Link>
        </div>
      </div>
      <ProductsWithSlide productsData={brand?.products_second_slider} />

      <div className="wrapper">
        <BanerSlider
          containerStyle={{
            padding: 0,
          }}
          imgStyle={{
            maxHeight: "none",
            borderRadius: "10px",
          }}
          bannerSliders={brand?.slider2}
        />
        <CategoryBlock
          myData={brand?.products_third_slider}
          showMoreBtn={false}
          url={false}
        />
        <div className={styles.generalBannerSection}>
          <div className={styles.generalBanner}>
            {brand?.banners["banner_4_" + currentLanguage] ? (
              <a href={brand?.banners.banner_4_href}>
                <img
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    brand?.banners["banner_4_" + currentLanguage]
                  }
                />
              </a>
            ) : (
              <LoadContent
                containerStyle={{
                  height: "400px",
                }}
              />
            )}
          </div>
        </div>
        <CategoryBlock
          myData={brand?.products_last_slider}
          showMoreBtn={true}
          url={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/brand/samsung/category`}
        />
        <div className={styles.lastInfoBrand}>
          <element
            dangerouslySetInnerHTML={{
              __html: brand?.info?.["text_2_" + currentLanguage],
            }}
          ></element>
        </div>
      </div>
    </>
  );
};

export default Samsung;
