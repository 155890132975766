import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import LoadContent from "../LoadContent";
import ProductCard from "../ProductCard/index";
import SliderNextArrowSvg from "../../assets/SVG/SliderNextArrowSvg";
import {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import useWindowSize from "../useWindowSize";

export default function ProductsWithSlide({
  productsData,
  title,
  containerStyle = {},
  noWrapper,
  refresh = false,
}) {
  const { width: windowWidth } = useWindowSize();
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef();
  const slidesPerView =
    windowWidth >= 1440
      ? 5
      : windowWidth >= 1024
      ? 4
      : windowWidth >= 320
      ? 2
      : "";

  const parentSwiperRef = useRef(null);

  const handleTouchStart = () => {
    if (parentSwiperRef.current) {
      parentSwiperRef.current.swiper.allowTouchMove = false;
    }
  };

  const handleTouchEnd = () => {
    if (parentSwiperRef.current) {
      parentSwiperRef.current.swiper.allowTouchMove = true;
    }
  };

  return (
    <div className={`${!noWrapper ? "wrapper" : ""} productCard`}>
      <div
        className={`${
          Object.entries(containerStyle).length ? containerStyle : ""
        }`}>
        {title ? <h3 className={styles.title}>{title}</h3> : null}
        <div
          style={{
            width: "100%",
            position: "relative",
            marginBottom: "15px",
          }}
          onMouseMove={() => windowWidth > 768 && setShowBtns(true)}
          onMouseOut={() => windowWidth > 768 && setShowBtns(false)}>
          {windowWidth < 600 ? (
            <div className="scrollRow">
              {!productsData?.length
                ? Array.from({ length: 7 }).map((_, index) => (
                    <SwiperSlide
                      style={{ width: "auto", display: "flex" }}
                      key={index}>
                      <LoadContent containerClassName={styles.loadContent} />
                    </SwiperSlide>
                  ))
                : (productsData.length > 6
                    ? [...productsData, ...productsData]
                    : productsData
                  ).map((item, index) => (
                    <SwiperSlide
                      style={{ width: "auto", display: "flex" }}
                      key={index}
                      className={styles.slideItem}>
                      <ProductCard
                        refresh={refresh}
                        product={item}
                      />
                    </SwiperSlide>
                  ))}
            </div>
          ) : (
            <>
              <>
                <div
                  className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                  style={{
                    display:
                      slidePosition.isBeginning || !showBtns ? "none" : "flex",
                  }}
                  onClick={() => swiperRef.current?.slidePrev()}>
                  <SliderNextArrowSvg />
                </div>
                <div
                  className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                  style={{
                    display: slidePosition.isEnd || !showBtns ? "none" : "flex",
                  }}
                  onClick={() => swiperRef.current?.slideNext()}>
                  <SliderNextArrowSvg />
                </div>
              </>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                loop
                autoplay
                // slidesPerView={"auto"}
                spaceBetween={19}
                breakpoints={{
                  // 425: {
                  //   slidesPerView: "auto",
                  //   spaceBetween: 5,
                  // },
                  600: {
                    slidesPerView: 2,
                    // spaceBetween: 20,
                  },
                  650: {
                    slidesPerView: 2.5,
                    // spaceBetween: 5,
                  },
                  970: {
                    slidesPerView: 4,
                    // spaceBetween: 10,
                  },
                  1230: {
                    slidesPerView: 5,
                    // spaceBetween: 10,
                  },
                }}
                ref={parentSwiperRef}
                onSlideChange={(e) => {
                  setSlidePosition({
                    isEnd: e.isEnd,
                    isBeginning: e.isBeginning,
                  });
                }}
                onSlidesLengthChange={(e) => {
                  if (slidesPerView === "auto") {
                    if (!isNaN(Math.ceil(e.width / 222)))
                      if (Math.ceil(e.width / 222) < e.slides.length) {
                        setSlidePosition({
                          isEnd: false,
                          isBeginning: true,
                        });
                      } else {
                        setSlidePosition({
                          isEnd: true,
                          isBeginning: true,
                        });
                      }
                  } else {
                    if (slidesPerView < e.slides.length) {
                      setSlidePosition({
                        isEnd: false,
                        isBeginning: true,
                      });
                    } else {
                      setSlidePosition({
                        isEnd: true,
                        isBeginning: true,
                      });
                    }
                  }
                }}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                className={styles.swiper}>
                {!productsData?.length
                  ? Array.from({ length: 7 }).map((_, index) => (
                      <SwiperSlide
                        style={{ width: "auto", display: "flex" }}
                        key={index}>
                        <LoadContent containerClassName={styles.loadContent} />
                      </SwiperSlide>
                    ))
                  : (productsData.length > 6
                      ? [...productsData, ...productsData]
                      : productsData
                    ).map((item, index) => (
                      <SwiperSlide
                        style={{ width: "auto", display: "flex" }}
                        key={index}
                        className={styles.slideItem}>
                        <ProductCard
                          refresh={refresh}
                          product={item}
                          onTouchStart={handleTouchStart}
                          onTouchEnd={handleTouchEnd}
                        />
                      </SwiperSlide>
                    ))}
              </Swiper>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
