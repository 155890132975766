import React, { useState } from "react";
import style from "./style.module.css";
import styleVue2 from "../../style..module.css";
import LoadContent from "../../../../components/LoadContent";
import ProductsWithSlide from "../../../../components/ProductsWithSlide";
import DetailsBigBanner from "../../components/DetailsBigBanner";
import DescriptionTitle from "../../components/DescriptionTitle";
import BanerSlider from "../../../../components/BanerSlider";
import { useSelector } from "react-redux";
import FromToPage from "../../../../components/FromToPage";
import langData from "../../../../lang/data";
import styles from "../../../../components/BanerSlider/style.module.css";
import stylesProductList from "../../../../screens/Category/styles.module.css";
import ProductList from "screens/Category/components/productList";
import { useSearchParams } from "react-router-dom";
import { selectCategory } from "store/selector";
import Attributes from "screens/Category/components/Attribute";
// import FilterProducts from "./FilterProducts";

const DetailsVue4 = ({ detailsList, isLoading, error, currentLanguage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [recordPage, setRecordPage] = useState(20);
  const { loading } = useSelector(selectCategory);

  const handleMoreProducts = () => {
    setLoad(true);
    setCurrentPage(1);
    searchParams.set("p", +detailsList?.data?.products?.length + recordPage);
    setSearchParams(searchParams);
  };

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.titles.Sales[currentLanguage],
            value: "",
            active: false,
          },
          {
            label: detailsList?.sale?.["name_" + currentLanguage],
            value: "",
            active: true,
          },
        ]}
      />
      <DetailsBigBanner
        isLoading={isLoading}
        currentLanguage={currentLanguage}
        detailsList={detailsList}
      />
      <div className={styleVue2.details_description}>
        {!isLoading && detailsList.sale ? (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: detailsList.sale?.["description_" + currentLanguage],
              }}
            />
          </>
        ) : (
          <>
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <div className={styleVue2.details_description_loader_item}>
                  <LoadContent
                    key={index}
                    containerClassName={styleVue2.details_description_loader}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
      {!isLoading && detailsList?.sale ? (
        <DescriptionTitle
          currentLanguage={currentLanguage}
          detailsList={detailsList}
        />
      ) : null}
      {!isLoading && detailsList?.products_first_slider && (
        <div className={style.details_vue1_slider}>
          <ProductsWithSlide
            productsData={detailsList?.products_first_slider}
            noWrapper={true}
          />
        </div>
      )}
      {!isLoading ? (
        <div className={style.details_banner_slider}>
          <BanerSlider
            bannerSliders={detailsList.sliders}
            currentLanguage={currentLanguage}
          />
        </div>
      ) : (
        <LoadContent containerClassName={styles.loadContent} />
      )}
      <Attributes
        styles={stylesProductList}
        setCurrentPage={setCurrentPage}
        category={detailsList?.data}
        loading={isLoading}
      />
      {!isLoading && detailsList?.data?.products?.length ? (
        <div style={{ marginTop: 60 }}>
          <ProductList
            load={load}
            styles={stylesProductList}
            handlePagination={(e) => {
              searchParams.set("page", e);
              setSearchParams(searchParams);
              setCurrentPage(e);
            }}
            currentPage={currentPage}
            handleMoreProducts={handleMoreProducts}
            recordPage={recordPage}
            setRecordPage={setRecordPage}
            category={detailsList?.data}
            loading={loading}
          />
        </div>
      ) : (
        <div style={{ marginTop: 60 }}>
          <LoadContent containerClassName={styles.loadContent} />
        </div>
      )}
      {/* <FilterProducts /> */}
      {/* detailsList?.products_first_slider */}
      {/* <SearchResult /> */}
    </div>
  );
};

export default DetailsVue4;
