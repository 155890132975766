import React, { useRef } from "react";
import FromToPage from "../../components/FromToPage";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../../components/LoadContent/index";
import styles from "./style.module.css";
import Pageination from "../../components/Pageination";
import langData from "../../lang/data";
import { useState } from "react";
import { useEffect } from "react";
import { getBlogPageData } from "../../store/MainSlice";
import { Link } from "react-router-dom";
import UseGetDate from "../../helpers/UseGetDate";

export default function Blog() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  const { currentLanguage, blogPageData } = useSelector(({ main }) => main);
  const [recordsPerPage] = useState(12);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = blogPageData?.Blog.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(
    blogPageData && blogPageData?.Blog.length / recordsPerPage
  );
  const titleRef = useRef();

  useEffect(() => {
    dispatch(getBlogPageData());
  }, []);
  // useEffect(() => {
  //   titleRef.current?.scrollIntoView({
  //     block: "start",
  //   });
  // }, [currentPage]);
  const bannerImg = {
    hy: "https://vlv.am/public/uploads/images/23-11-2022/637de9c6a0d4c.png",
    ru: "https://vlv.am/public/uploads/images/23-11-2022/637de9c6b788e.png",
    en: "https://vlv.am/public/uploads/images/23-11-2022/637de9c6ce5a6.png",
  };

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.header.menu.Blog?.[currentLanguage],
            value: "/blog",
            active: true,
          },
        ]}
      />
      <div className={styles.creditBanner}>
        <img
          className={styles.creditBannerImg}
          src={bannerImg[currentLanguage]}
          alt="img"
        />
      </div>
      <div className={styles.blogSection}>
        <h1 ref={titleRef}>{langData.header.menu.Blog?.[currentLanguage]}</h1>
        <div className={styles.main}>
          {!blogPageData
            ? Array.from({ length: 12 }).map((_, index) => (
                <div className={styles.blogItem} key={index}>
                  <LoadContent containerClassName={styles.loadContentImg} />
                  <LoadContent containerClassName={styles.loadContentTitle} />
                  <LoadContent containerClassName={styles.loadContentDate} />
                </div>
              ))
            : currentRecords.map((item, index) => {
                return (
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/blog/${item.slug}`}
                    className={styles.blogItem}
                    key={index}
                  >
                    <img
                      className={styles.blogItemImg}
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        item["image_url_" + currentLanguage]
                      }
                      alt={item["title_" + currentLanguage]}
                    />
                    <h4 className={styles.blogItemTitle}>
                      {item?.["title_" + currentLanguage]?.substring(0, 45)}
                      ...
                    </h4>

                    <span className={styles.blogItemDate}>
                      {UseGetDate(item.created_at, currentLanguage)}
                    </span>
                  </Link>
                );
              })}
        </div>
        <div className={styles.pageination}>
          <Pageination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    </div>
  );
}
