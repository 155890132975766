import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import langData from "../../../../lang/data";
import { Link } from "react-router-dom";
import { salesDetailsFetch } from "../../../../store/SalesDetails/salesDetailsFetch";

export default function Sales({ saleBackgroundColor }) {
  const { sales } = useSelector(({ home }) => home);
  const dispatch = useDispatch();
  const { currentLanguage } = useSelector(({ main }) => main);
  const { isLoading, error, detailsList } = useSelector(
    (state) => state.SalesDetailsSlice
  );

  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });
  const handleClick = (item) => {
    dispatch(salesDetailsFetch(item.id));
  };

  const swiperRef = useRef();
  const slidesPerView =
    window.innerWidth > 1024 ? "auto" : window.innerWidth > 600 ? 4 : 2.5;

  if (!sales?.length) {
    return null;
  }

  return (
    <div style={{ backgroundColor: saleBackgroundColor, paddingBottom: "1px" }}>
      <div className="wrapper">
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>
            {langData.titles.Sales[currentLanguage]}
          </h3>
          <Link
            to={`${
              currentLanguage !== "hy" ? "/" + currentLanguage : ""
            }/sales`}>
            <span>{langData.buttons.view_all[currentLanguage]}</span>
          </Link>
        </div>
        <div
          style={{
            width: "100%",
          }}
          onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
          onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}>
          {window.innerWidth > 768 && (
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                style={{
                  display: slidePosition.isBeginning ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slidePrev()}>
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                style={{
                  display: slidePosition.isEnd || !showBtns ? "none" : "flex",
                }}
                onClick={() => swiperRef.current?.slideNext()}>
                <SliderNextArrowSvg />
              </div>
            </>
          )}

          <Swiper
            spaceBetween={20}
            slidesPerView={slidesPerView}
            style={{ paddingBottom: "15px" }}
            onSlideChange={(e) => {
              setSlidePosition({
                isEnd: e.isEnd,
                isBeginning: e.isBeginning,
              });
            }}
            onSlidesLengthChange={(e) => {
              if (slidesPerView === "auto") {
                if (!isNaN(Math.ceil(e.width / 244)))
                  if (Math.ceil(e.width / 244) < e.slides.length) {
                    setSlidePosition({
                      isEnd: false,
                      isBeginning: true,
                    });
                  } else {
                    setSlidePosition({
                      isEnd: true,
                      isBeginning: true,
                    });
                  }
              } else {
                if (slidesPerView < e.slides.length) {
                  setSlidePosition({
                    isEnd: false,
                    isBeginning: true,
                  });
                } else {
                  setSlidePosition({
                    isEnd: true,
                    isBeginning: true,
                  });
                }
              }
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            centeredSlides={false}>
            {!sales.length
              ? Array.from({ length: 7 }).map((_, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    key={index}>
                    <LoadContent containerClassName={styles.loadContent} />
                  </SwiperSlide>
                ))
              : sales?.map((item, index) => {
                  let imageUri = item.gallary_images?.find(
                    (elm) => elm.lang === currentLanguage
                  )?.image;
                  return (
                    <SwiperSlide
                      key={index}
                      style={{ width: "auto", display: "flex" }}>
                      {item.view == 4 ? (
                        <a
                          href={`https://vlv.am/sales/${item.id}?lang=${currentLanguage}`}>
                          <img
                            className={styles.saleImg}
                            src={process.env.REACT_APP_STORAGE_URL + imageUri}
                            alt="VLV"
                          />
                        </a>
                      ) : (
                        <Link
                          to={`${
                            currentLanguage !== "hy"
                              ? "/" + currentLanguage
                              : ""
                          }/sale/${item.id}/${item.view}`}
                          onClick={() => handleClick(item)}>
                          <img
                            className={styles.saleImg}
                            src={process.env.REACT_APP_STORAGE_URL + imageUri}
                            // src={process.env.REACT_APP_STORAGE_URL + imageUri}
                            alt="VLV"
                          />
                        </Link>
                      )}
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
