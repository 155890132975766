import React, { useRef, useState } from "react";
import style from "./style.module.css";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { useSelector } from "react-redux";

const TopCatalogSlides = ({ isLoading, responseList }) => {
  const [showBtns, setShowBtns] = useState(false);
  const { currentLanguage } = useSelector(({ main }) => main);
  const swiperRef = useRef();
  return (
    <div className={style.container_catalog_slides}>
      <div
        className={style.leftContent}
        onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
        onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
      >
        {isLoading ? (
          <LoadContent containerClassName={style.leftLoadContent} />
        ) : (
          <>
            {responseList?.slider1 ? (
              <>
                <>
                  {window.innerWidth < 600 ? (
                    <div className="scrollRow">
                      {responseList.slider1.map((item, index) => (
                        <SwiperSlide key={index}>
                          <a href={item?.href} className={style.sliderA}>
                            <picture className={style.catalog_slides_img}>
                              <img
                                className={style.leftSliderImg}
                                src={
                                  process.env.REACT_APP_STORAGE_URL +
                                  item["image_" + currentLanguage]
                                }
                                type="image/webp"
                                alt="VLV"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  // currentTarget.src = require("../../../../assets/defaultImg.png");
                                }}
                              />
                            </picture>
                          </a>
                        </SwiperSlide>
                      ))}
                    </div>
                  ) : (
                    <>
                      <>
                        <div
                          className={`${style.slideBtn} ${style.slidePrevArrow}`}
                          style={
                            showBtns ? { display: "flex" } : { display: "none" }
                          }
                          onClick={() => swiperRef.current?.slidePrev()}
                        >
                          <SliderNextArrowSvg />
                        </div>
                        <div
                          className={`${style.slideBtn} ${style.slideNextArrow}`}
                          style={
                            showBtns ? { display: "flex" } : { display: "none" }
                          }
                          onClick={() => swiperRef.current?.slideNext()}
                        >
                          <SliderNextArrowSvg />
                        </div>
                      </>

                      <Swiper
                        loop
                        autoplay
                        onBeforeInit={(swiper) => {
                          swiperRef.current = swiper;
                        }}
                        modules={[Pagination, Autoplay]}
                        pagination={{ clickable: true }}
                        grabCursor={true}
                        centeredSlides={true}
                        className={style.leftSlider}
                      >
                        {responseList.slider1.map((item, index) => (
                          <SwiperSlide key={index}>
                            <a href={item?.href}>
                              <picture className={style.catalog_slides_img}>
                                <img
                                  className={style.leftSliderImg}
                                  src={
                                    process.env.REACT_APP_STORAGE_URL +
                                    item["image_" + currentLanguage]
                                  }
                                  type="image/webp"
                                  alt="VLV"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    // currentTarget.src = require("../../../../assets/defaultImg.png");
                                  }}
                                />
                              </picture>
                            </a>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </>
                  )}
                </>
              </>
            ) : null}
          </>
        )}
      </div>

      <div className={style.rightContent}>
        <div className={style.rightSlider}>
          {isLoading ? (
            <LoadContent containerClassName={style.leftLoadContent} />
          ) : (
            <>
              {responseList?.slider2 ? (
                <>
                  <Swiper>
                    {responseList?.slider2.map((item, index) => {
                
                      return (
                        <SwiperSlide key={index}>
                          <a href={item?.href}>
                            <img
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                item?.["image_" + currentLanguage]
                              }
                              alt="VLV"
                            />
                          </a>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </>
              ) : null}
            </>
          )}
        </div>

        <div className={style.rightSlider}>
          {isLoading ? (
            <LoadContent containerClassName={style.leftLoadContent} />
          ) : (
            <>
              {responseList?.slider3 ? (
                <>
                  <Swiper>
                    {responseList?.slider3.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <a href={item?.href}>
                            <img
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                item?.["image_" + currentLanguage]
                              }
                              alt="VLV"
                            />
                          </a>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopCatalogSlides;
