import React, {
  useState,
  memo,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";
import styles from "./style.module.css";
import FacebookSvg from "../../assets/SVG/FacebookSvg";
import InstagramSvg from "../../assets/SVG/InstagramSvg";
import WhatsappSvg from "../../assets/SVG/WhatsappSvg";
import ViberSvg from "../../assets/SVG/ViberSvg";
import ButtonArrowSvg from "../../assets/SVG/ButtonArrowSvg";
import PhoneSvg from "../../assets/SVG/PhoneSvg";
import Currency from "./components/Currency";
import CatalogSvg from "../../assets/SVG/CatalogSvg";
import UserSvg from "../../assets/SVG/UserSvg";
import CompareSvg from "../../assets/SVG/CompareSvg";
import HeartSvg from "../../assets/SVG/HeartSvg";
import ShopCartSvg from "../../assets/SVG/ShopCartSvg";
import HeaderCategorys from "./components/HeaderCategorys";
import Catalog from "./components/Catalog";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import CatalogMobile from "./components/CatalogMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  getCartPageProducts,
  getCatalogSliders,
  getSearchInfo,
  getWishlistCartCount,
  setSearchInfo,
  setShopCartPositions,
  setShowLoginModal,
} from "../../store/MainSlice";
import UserMenu from "./components/UserMenu";
import UseOutSideClick from "../../helpers/UseOutSideClick";
import SearchModal from "./components/SearchModal";
import langData from "../../lang/data";
import { getSearchRedirectInfo } from "../../store/SearchPageSlice";

export default memo(function Header() {
  const [headLineWidth, setHeadLineWidth] = useState(0);
  const [showPhones, setShowPhones] = useState(false);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [requestToken, setRequestToken] = useState(null);
  const [fixHeader, setFixHeader] = useState(false);
  const [closeSearchModal, setCloseSearchModal] = useState(false);

  const {
    isAuthed,
    favorites,
    compares,
    shopCartCount,
    currentLanguage,
    currentCurrency,
    searchInfo,
  } = useSelector(({ main }) => main);

  const dispatch = useDispatch();

  const menuData = [
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/contacts`,
      label: langData.header.menu.Our_shops,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/blog`,
      label: langData.header.menu.Blog,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/job`,
      label: langData.header.menu.Jobs,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/about-us`,
      label: langData.header.menu.About_us,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/delivery`,
      label: langData.header.menu.Delivery,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/credit`,
      label: langData.header.menu.Credit,
    },
    {
      href: `${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/contacts`,
      label: langData.header.menu.Contacts,
    },
  ];

  const shopCartRef = useRef();
  const openedPhonesRef = useRef();
  const fixedHeaderRef = useRef();
  UseOutSideClick(openedPhonesRef, () => setShowPhones(false));

  useLayoutEffect(() => {
    dispatch(getWishlistCartCount());
    dispatch(getCatalogSliders());
    const positions = shopCartRef.current?.getBoundingClientRect();
    dispatch(
      setShopCartPositions({ top: positions?.top, left: positions?.left })
    );
  }, [shopCartRef]);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (redirect && Object.keys(searchInfo || {}).length) {
      if (
        Object.keys(searchInfo?.category || {}).length &&
        Object.keys(searchInfo?.brand || {}).length
      ) {
        window.location.href = `https://vlv.am/category/${searchInfo?.category?.slug}?item=category&b=${searchInfo?.brand?.id}&t=1&lang=${currentLanguage}`;
      } else if (Object.keys(searchInfo?.brand || {}).length) {
        window.location.href = `https://vlv.am/brand/${searchInfo?.brand?.slug}?item=brand&lang=${currentLanguage}`;
      } else if (Object.keys(searchInfo?.category || {}).length) {
        window.location.href = `https://vlv.am/category/${searchInfo?.category?.slug}?item=category&lang=${currentLanguage}`;
      } else {
        window.location.href = `https://vlv.am/category/${searchText}?item=search&lang=hy`;
      }
    }
  }, [redirect, searchInfo]);

  useEffect(() => {
    setSearchText("");
    dispatch(setSearchInfo(null));
  }, [location]);

  if (window.innerWidth <= 992) return <MobileHeader />;

  document.onscroll = function () {
    setFixHeader(document.body?.parentNode.scrollTop >= 62);
    var pos = getVerticalScrollPercentage(document.body);
    setHeadLineWidth(Math.round(pos));
  };

  function getVerticalScrollPercentage(elm) {
    var p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }

  return (
    <>
      {window.innerWidth > 1024 ? (
        <Catalog
          openCatalog={openCatalog}
          setOpenCatalog={setOpenCatalog}
        />
      ) : (
        <CatalogMobile
          openCatalog={openCatalog}
          setOpenCatalog={setOpenCatalog}
        />
      )}

      <header
        className={styles.container}
        style={{ paddingTop: fixHeader && "62px" }}>
        <div
          className={styles.headLine}
          style={{ width: headLineWidth + "%" }}
        />

        <div className={styles.wrapper} style={{overflow: 'unset'}}>
          <div className={styles.headerThreeBlocks}>
            <div className={styles.headerTop}>
              <div className={styles.leftContent}>
                {menuData.map((item, index) => {
                  return (
                    <Link
                      to={item.href}
                      key={index}
                      className={styles.menuItem}>
                      {item.label?.[currentLanguage]}
                    </Link>
                  );
                })}
              </div>
              <div className={styles.rightContent}>
                <div className={styles.socIconsContainer}>
                  <a
                    href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d"
                    aria-label="facebook">
                    <FacebookSvg className={styles.socIcon} />
                  </a>
                  <a
                    href="https://www.instagram.com/vlv_centre/"
                    aria-label="instagram">
                    <InstagramSvg className={styles.socIcon} />
                  </a>
                  <a
                    href="https://wa.me/37491296655"
                    aria-label="whatsapp">
                    <WhatsappSvg className={styles.socIcon} />
                  </a>
                  <a
                    href="viber://chat?number=+37491296655/"
                    aria-label="viber">
                    <ViberSvg className={styles.socIcon} />
                  </a>
                </div>
                <Currency />
                <div
                  className={styles.phones}
                  ref={openedPhonesRef}>
                  <PhoneSvg />
                  <a
                    href="tel:010349944"
                    className={styles.phoneBtn}>
                    010-34-99-44
                  </a>
                  <ButtonArrowSvg onClick={() => setShowPhones(!showPhones)} />
                  {showPhones && (
                    <div className={styles.phonesOpened}>
                      <a href="tel:010349944">010-34-99-44</a>
                      <a href="tel:010449968">010-44-99-68</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              ref={fixedHeaderRef}
              className={`${styles.headerBottomContainer} ${
                fixHeader && styles.fixedHeader
              }`}>
              <div className={styles.headerBottom}>
                <Link
                  to={"/" + (currentLanguage !== "hy" ? currentLanguage : "")}>
                  <img
                    className={styles.logo}
                    src={
                      currentLanguage === "hy"
                        ? "https://vlv.am/public/logos/header_hy.png"
                        : "https://vlv.am/public/logos/header_ru.svg"
                    }
                    alt="VLV"
                  />
                </Link>
                <div
                  className={styles.catalogBtn}
                  onClick={() => setOpenCatalog(true)}>
                  <CatalogSvg />
                  <span>{langData.buttons.catalog[currentLanguage]}</span>
                </div>
                <div className={styles.searchContainer}>
                  <input
                    placeholder={
                      langData.header.searchPlaceholder[currentLanguage]
                    }
                    className={styles.searchInput}
                    onFocus={(e) => {
                      if (e.target.value.length) {
                        setCloseSearchModal(false);
                      }
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        if (event.target.value.length) {
                          dispatch(
                            getSearchRedirectInfo(
                              searchText,
                              navigate,
                              currentLanguage
                            )
                          );
                          setCloseSearchModal(true);
                        }
                      }
                    }}
                    value={searchText}
                    onChange={(e) => {
                      setCloseSearchModal(false);
                      setSearchText(e.target.value);
                      if (e.target.value.length) {
                        dispatch(
                          getSearchInfo(
                            e.target.value,
                            requestToken,
                            setRequestToken
                          )
                        );
                      } else {
                        dispatch(setSearchInfo(null));
                      }
                    }}
                    maxLength={150}
                  />
                  <button
                    disabled={searchText?.length ? false : true}
                    className={styles.searchBtn}
                    onClick={() => {
                      dispatch(
                        getSearchRedirectInfo(
                          searchText,
                          navigate,
                          currentLanguage
                        )
                      );
                      setCloseSearchModal(true);
                    }}>
                    {langData.buttons.search[currentLanguage]}
                  </button>
                  <SearchModal
                    closeSearchModal={closeSearchModal}
                    setCloseSearchModal={setCloseSearchModal}
                  />
                </div>
                <div
                  className={styles.userBtn}
                  onClick={() => {
                    if (localStorage.getItem("access_token")) {
                      setShowUserMenu(!showUserMenu);
                    } else if (
                      !localStorage.getItem("access_token") &&
                      !showUserMenu
                    ) {
                      dispatch(setShowLoginModal(true));
                    }
                  }}>
                  <UserSvg />
                  {showUserMenu && localStorage.getItem("access_token") && (
                    <UserMenu
                      containerStyle={false}
                      setShowUserMenu={setShowUserMenu}
                    />
                  )}
                </div>
                <div className={styles.compareAndFavContainer}>
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/compare`}
                    aria-label="compare">
                    {compares.length > 0 && (
                      <span className={styles.count}>{compares.length}</span>
                    )}
                    <CompareSvg active={compares.length > 0} />
                  </Link>
                  <span className={styles.line} />
                  <Link
                    to={`${
                      currentLanguage !== "hy" ? "/" + currentLanguage : ""
                    }/favorite`}
                    aria-label="heart">
                    <HeartSvg active={favorites?.length > 0} />
                    {favorites?.length > 0 && (
                      <span className={styles.count}>{favorites?.length}</span>
                    )}
                  </Link>
                </div>
                <Link
                  to={`${
                    currentLanguage !== "hy" ? "/" + currentLanguage : ""
                  }/cart`}
                  onClick={() => {
                    dispatch(getCartPageProducts());
                  }}>
                  {/* <a href={`https://vlv.am/cart?lang=${currentLanguage}`}> */}
                  <div
                    className={`${styles.shopCartBtn} ${
                      shopCartCount?.count > 0 && styles.shopCartBtnActive
                    }`}
                    ref={shopCartRef}>
                    <ShopCartSvg />
                    <div className={styles.shopCartInfo}>
                      <span>
                        {langData.buttons.cart[currentLanguage]}{" "}
                        {shopCartCount?.count > 0 && shopCartCount.count}
                      </span>
                      <span>
                        {shopCartCount?.count > 0
                          ? shopCartCount.price.toLocaleString("en-US")
                          : 0}{" "}
                        {currentCurrency.currency}
                      </span>
                    </div>
                  </div>
                  {/* </a> */}
                </Link>
              </div>
            </div>
            <HeaderCategorys />
          </div>
        </div>
      </header>
    </>
  );
});
