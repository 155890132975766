import { configureStore } from "@reduxjs/toolkit";
import MainSlice from "./MainSlice";
import homeSlice from "./HomeSlice";
import JobPageSlice from "./jobPage/jobPageSlice";
import ContactPageSlice from "./contactPage/contactPageSlice";
import HeaderCategoriesSlice from "./headerCategories/headerCategoriesSlice";
import SearchNullSlice from "./SearchNull/searchNullSlice";
import BrandSeeAllSlice from "./brandSeeAll/brandSeeAllSlice";
import searchPageSlice from "./SearchPageSlice";
import SalesDetailsSlice from "./SalesDetails/salesDetailsSlice";
import VikasSlice from "./Vikas/VikasSlice";
import PrivacyPolicySlice from "./privacyPolicy/privacyPolicySlice";
import FrequentQuestionsSlice from "./frequentQuestions/frequentQuestionsSlice";
import ServiceSlice from "./service/serviceSlice";
import saleFiltrSlice from "./SaleFiltrSlice";
import product from "./slices/productSlice/productSlice";
import brand from "./slices/brandSlice/brandSlice";
import sliders from "./sliders/sliderSlice";
import category from "./slices/category/categorySlice";
import auth from "./slices/auth/authSlices";
import getStoriesSlice from "../components/Stories/getStoriesSlice";

export const store = configureStore({
  reducer: {
    main: MainSlice,
    home: homeSlice,
    searchPage: searchPageSlice,
    saleFiltr: saleFiltrSlice,
    JobPageSlice,
    ContactPageSlice,
    HeaderCategoriesSlice,
    SearchNullSlice,
    BrandSeeAllSlice,
    SalesDetailsSlice,
    PrivacyPolicySlice,
    FrequentQuestionsSlice,
    ServiceSlice,
    VikasSlice,
    product,
    brand,
    sliders,
    category,
    auth,
    getStoriesSlice,
  },
});
