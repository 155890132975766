import React from "react";

export default function USAFlagSvg(props) {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1005_8435)">
        <path d="M17.9967 0H0V10.9971H17.9967V0Z" fill="#FF4B55" />
        <path
          d="M0.00652174 0.781982H17.9967C18 0.810628 18.0033 0.839274 18.0033 0.870784V1.71297H0V0.867919C0 0.839274 0.00326087 0.810628 0.00652174 0.781982ZM18 2.48354V3.41167H0V2.48354H18ZM18 4.18511V5.11323H0V4.18511H18ZM18 5.88667V6.81479H0V5.88667H18ZM18 7.58823V8.51636H0V7.58823H18ZM18 9.28979V10.1291C18 10.1578 17.9967 10.1893 17.9935 10.2179H0.00652174C0.00326087 10.1921 0 10.1606 0 10.132V9.29266H18V9.28979Z"
          fill="white"
        />
        <path
          d="M6.78261 0V5.92396H0C0 3.94931 0 1.97465 0 0C2.26087 0 4.52173 0 6.78261 0Z"
          fill="#41479B"
        />
        <path
          d="M0.400705 0.819336L0.625705 1.43522L0.0322266 1.05423H0.769183L0.172444 1.43522L0.400705 0.819336Z"
          fill="white"
        />
        <path
          d="M0.400705 2.01099L0.625705 2.62688L0.0322266 2.24589H0.769183L0.172444 2.62688L0.400705 2.01099Z"
          fill="white"
        />
        <path
          d="M0.400705 3.20264L0.625705 3.81852L0.0322266 3.43753H0.769183L0.172444 3.81852L0.400705 3.20264Z"
          fill="white"
        />
        <path
          d="M0.400705 4.39429L0.625705 5.01018L0.0322266 4.62918H0.769183L0.172444 5.01018L0.400705 4.39429Z"
          fill="white"
        />
        <path
          d="M1.1966 0.223389L1.42486 0.839274L0.828125 0.458285H1.56834L0.968343 0.839274L1.1966 0.223389Z"
          fill="white"
        />
        <path
          d="M1.1966 1.41504L1.42486 2.03092L0.828125 1.64993H1.56834L0.968343 2.03092L1.1966 1.41504Z"
          fill="white"
        />
        <path
          d="M1.1966 2.60669L1.42486 3.22258L0.828125 2.84158H1.56834L0.968343 3.22258L1.1966 2.60669Z"
          fill="white"
        />
        <path
          d="M1.1966 3.79834L1.42486 4.41423L0.828125 4.03324H1.56834L0.968343 4.41423L1.1966 3.79834Z"
          fill="white"
        />
        <path
          d="M1.1966 4.98999L1.42486 5.60587L0.828125 5.22488H1.56834L0.968343 5.60587L1.1966 4.98999Z"
          fill="white"
        />
        <path
          d="M1.99544 0.819336L2.22369 1.43522L1.62695 1.05423H2.36391L1.76717 1.43522L1.99544 0.819336Z"
          fill="white"
        />
        <path
          d="M1.99544 2.01099L2.22369 2.62688L1.62695 2.24589H2.36391L1.76717 2.62688L1.99544 2.01099Z"
          fill="white"
        />
        <path
          d="M1.99544 3.20264L2.22369 3.81852L1.62695 3.43753H2.36391L1.76717 3.81852L1.99544 3.20264Z"
          fill="white"
        />
        <path
          d="M1.99544 4.39429L2.22369 5.01018L1.62695 4.62918H2.36391L1.76717 5.01018L1.99544 4.39429Z"
          fill="white"
        />
        <path
          d="M2.79474 0.223389L3.01974 0.839274L2.42627 0.458285H3.16322L2.56649 0.839274L2.79474 0.223389Z"
          fill="white"
        />
        <path
          d="M2.79474 1.41504L3.01974 2.03092L2.42627 1.64993H3.16322L2.56649 2.03092L2.79474 1.41504Z"
          fill="white"
        />
        <path
          d="M2.79474 2.60669L3.01974 3.22258L2.42627 2.84158H3.16322L2.56649 3.22258L2.79474 2.60669Z"
          fill="white"
        />
        <path
          d="M2.79474 3.79834L3.01974 4.41423L2.42627 4.03324H3.16322L2.56649 4.41423L2.79474 3.79834Z"
          fill="white"
        />
        <path
          d="M2.79474 4.98999L3.01974 5.60587L2.42627 5.22488H3.16322L2.56649 5.60587L2.79474 4.98999Z"
          fill="white"
        />
        <path
          d="M3.59016 0.819336L3.81841 1.43522L3.22168 1.05423H3.95863L3.3619 1.43522L3.59016 0.819336Z"
          fill="white"
        />
        <path
          d="M3.59016 2.01099L3.81841 2.62688L3.22168 2.24589H3.95863L3.3619 2.62688L3.59016 2.01099Z"
          fill="white"
        />
        <path
          d="M3.59016 3.20264L3.81841 3.81852L3.22168 3.43753H3.95863L3.3619 3.81852L3.59016 3.20264Z"
          fill="white"
        />
        <path
          d="M3.59016 4.39429L3.81841 5.01018L3.22168 4.62918H3.95863L3.3619 5.01018L3.59016 4.39429Z"
          fill="white"
        />
        <path
          d="M4.38899 0.223389L4.61725 0.839274L4.02051 0.458285H4.75746L4.16073 0.839274L4.38899 0.223389Z"
          fill="white"
        />
        <path
          d="M4.38899 1.41504L4.61725 2.03092L4.02051 1.64993H4.75746L4.16073 2.03092L4.38899 1.41504Z"
          fill="white"
        />
        <path
          d="M4.38899 2.60669L4.61725 3.22258L4.02051 2.84158H4.75746L4.16073 3.22258L4.38899 2.60669Z"
          fill="white"
        />
        <path
          d="M4.38899 3.79834L4.61725 4.41423L4.02051 4.03324H4.75746L4.16073 4.41423L4.38899 3.79834Z"
          fill="white"
        />
        <path
          d="M4.38899 4.98999L4.61725 5.60587L4.02051 5.22488H4.75746L4.16073 5.60587L4.38899 4.98999Z"
          fill="white"
        />
        <path
          d="M5.18489 0.819336L5.41314 1.43522L4.81641 1.05423H5.55336L4.95989 1.43522L5.18489 0.819336Z"
          fill="white"
        />
        <path
          d="M5.18489 2.01099L5.41314 2.62688L4.81641 2.24589H5.55336L4.95989 2.62688L5.18489 2.01099Z"
          fill="white"
        />
        <path
          d="M5.18489 3.20264L5.41314 3.81852L4.81641 3.43753H5.55336L4.95989 3.81852L5.18489 3.20264Z"
          fill="white"
        />
        <path
          d="M5.18489 4.39429L5.41314 5.01018L4.81641 4.62918H5.55336L4.95989 5.01018L5.18489 4.39429Z"
          fill="white"
        />
        <path
          d="M5.98372 0.223389L6.21198 0.839274L5.61523 0.458285H6.35219L5.75545 0.839274L5.98372 0.223389Z"
          fill="white"
        />
        <path
          d="M5.98372 1.41504L6.21198 2.03092L5.61523 1.64993H6.35219L5.75545 2.03092L5.98372 1.41504Z"
          fill="white"
        />
        <path
          d="M5.98372 2.60669L6.21198 3.22258L5.61523 2.84158H6.35219L5.75545 3.22258L5.98372 2.60669Z"
          fill="white"
        />
        <path
          d="M5.98372 3.79834L6.21198 4.41423L5.61523 4.03324H6.35219L5.75545 4.41423L5.98372 3.79834Z"
          fill="white"
        />
        <path
          d="M5.98372 4.98999L6.21198 5.60587L5.61523 5.22488H6.35219L5.75545 5.60587L5.98372 4.98999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1005_8435">
          <rect width="18" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
