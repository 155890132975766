import React from "react";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";

const DetailsCategories = ({ list, onClick,detailsList }) => {

  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  if (window.innerWidth < 600) {
    return (
      <div className={`${style.details_categories_item} scrollBar`}>
        {list.map((item, index) => {
          return (
            <Link
             to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/category/filter/${item.slug}${detailsList?.sale?.brand_ids ? `?b=${detailsList.sale.brand_ids}` : "" }`}
              key={index}
              className={style.details_simple}
            >
              {item["name_" + currentLanguage]}
            </Link>
          );
        })}
      </div>
    );
  }

  return (
    <Swiper
      style={{ height: "80px", paddingBottom: "10px", paddingTop: "20px" }}
      modules={[Scrollbar]}
      slidesPerView={"auto"}
      scrollbar={{ draggable: true, dragSize: "auto" }}
      spaceBetween={10}
    >
      {list.map((item, index) => {
 
        return (
          <SwiperSlide
            style={{
              width: "auto",
            }}
          >
            <Link
               to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/category/filter/${item.slug}${detailsList?.sale?.brand_ids ? `?b=${detailsList.sale.brand_ids}` : "" }`}
              key={index}
              className={style.details_simple}
            >
              {item["name_" + currentLanguage]}
            </Link>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default DetailsCategories;
