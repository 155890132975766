import React, { useRef } from "react";
import styles from "./style.module.css";
import CompareSvg from "../../../../assets/SVG/CompareSvg";
import HeartSvg from "../../../../assets/SVG/HeartSvg";
import ShopCartSvg from "../../../../assets/SVG/ShopCartSvg";
import lang from "../../../../lang/lang";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardStore,
  addCompare,
  addCompares,
  addFavorites,
  addShopCartCount,
  addWishList,
  removeCompares,
  removeFavorites,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setShopCartProductAnimation,
} from "../../../../store/MainSlice";
import UseCalcPrice from "../../../../helpers/UseCalcPrice";
import defaultImg from "../../../../assets/defaultImg.png"; 
export default function StikckyFooter({
  showBottomSticky,
  installingBtnBlockActive,
  count,
}) {
  const { productInfo, currentLanguage, currentCurrency, favorites, compares } =
    useSelector(({ main }) => main);
  const product = productInfo?.product;

  const productImgRef = useRef();

  const dispatch = useDispatch();

  const handleImageError = (event) => {
    event.target.onerror = null; // Prevent infinite loop
    event.target.src = defaultImg; // Set default image on error
  };

  if (!Object.keys(product || {}).length) return null;
  return (
    <section
      className={`${styles.container} ${showBottomSticky && styles.show}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.productImg} ref={productImgRef}>
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              (product?.gallary_images_api?.[0]?.images_source || "")
            }
            onError={handleImageError}
            alt="Product Image"
          />
        </div>
        <div className={styles.nameBlock}>
          <span className={styles.name}>
            {product?.brand.name + " " + product?.product_name}
          </span>
        </div>
        {product.sticker[0]?.["image_" + currentLanguage] && (
          <img
            className={styles.stickerImg}
            src={
              process.env.REACT_APP_STORAGE_URL +
              product.sticker[0]?.["image_" + currentLanguage]
            }
            alt="Sticker"
          />
        )}

        <div className={styles.nameBlock}>
          <div className={styles.price_block}>
            <span className={styles.priceText}>
              {" "}
              {lang[currentLanguage].price}
            </span>
            <span className={styles.priceNumber}>
              {product?.skus?.[0].promo_price ||
              (product?.online_price && product?.online_selling_price) ? (
                <>
                  <span className={styles.newPrice}>
                    {UseCalcPrice(
                      product?.skus?.[0].promo_price ||
                        (product?.online_price &&
                          product?.online_selling_price),
                      currentCurrency
                    )}
                  </span>
                  <div className={styles.oldPriceBlock}>
                    <span className={styles.oldPrice}>
                      {UseCalcPrice(
                        product?.skus?.[0].selling_price,
                        currentCurrency
                      )}
                    </span>
                    <span className={styles.oldPriceLine} />
                  </div>
                </>
              ) : (
                <span className={styles.price}>
                  {UseCalcPrice(
                    product?.skus?.[0].selling_price,
                    currentCurrency
                  )}
                </span>
              )}
            </span>
          </div>
        </div>

        <div className={styles.compareHeart}>
          <div
            onClick={() => {
              if (!compares.includes(product?.skus?.[0]?.id)) {
                if (product?.gallary_images_api?.[0]?.images_source) {
                  const { top, left } =
                    productImgRef.current?.getBoundingClientRect();

                  dispatch(
                    setCompareProductAnimation({
                      image: product?.gallary_images_api?.[0]?.images_source,
                      position: {
                        top,
                        left,
                      },
                    })
                  );
                }

                dispatch(addCompares(product?.skus?.[0]?.id));
              } else {
                dispatch(removeCompares(product?.skus?.[0]?.id));
              }
              dispatch(
                addCompare({
                  product_sku_id: product.skus?.[0]?.id,
                })
              );
            }}
          >
            <CompareSvg active={compares.includes(product?.skus?.[0]?.id)} />
          </div>
          <span />
          <div
            onClick={() => {
              if (!favorites.includes(product?.id)) {
                if (product?.gallary_images_api?.[0]?.images_source) {
                  const { top, left } =
                    productImgRef.current?.getBoundingClientRect();

                  dispatch(
                    setHeartProductAnimation({
                      image: product?.gallary_images_api?.[0]?.images_source,
                      position: {
                        top,
                        left,
                      },
                    })
                  );
                }
                dispatch(addFavorites(product?.id));
              } else {
                dispatch(removeFavorites(product?.id));
              }
              dispatch(
                addWishList({
                  seller_product_id: product?.id,
                })
              );
            }}
          >
            <HeartSvg active={favorites.includes(product?.id)} />
          </div>
        </div>
        <div
          className={`${styles.buyBtn} ${styles.addCartBtn}`}
          onClick={() => {
            if (product?.gallary_images_api?.[0].images_source) {
              const { top, left } =
                productImgRef.current?.getBoundingClientRect();

              dispatch(
                setShopCartProductAnimation({
                  image: product?.gallary_images_api?.[0]?.images_source,
                  position: {
                    top,
                    left,
                  },
                })
              );
            }
            dispatch(
              addShopCartCount({
                price:
                  +(
                    (product?.skus?.[0].promo_price ||
                      product?.skus[0]?.selling_price) -
                    ((!installingBtnBlockActive && product.installing_price) ||
                      0)
                  ) * count,
                count: count,
              })
            );
            dispatch(
              addCardStore({
                price:
                  +product?.promo_price ||
                  +(product?.online_price && product?.online_selling_price) ||
                  +product?.skus[0]?.selling_price,
                qty: 1,
                product_id: product?.skus?.[0]?.id,
                seller_id: 1,
                shipping_method_id: 0,
                type: "product",
                is_buy_now: "no",
              })
            );
          }}
        >
          <ShopCartSvg className={styles.cardBtnSvg} />
          <span>{lang[currentLanguage].add}</span>
        </div>
      </div>
    </section>
  );
}
