import React, { useState } from "react";

import FromToPage from "../../components/FromToPage";
import BanerSlider from "../../components/BanerSlider";
import { getBanerSliders, getBestDealProduct } from "../../store/HomeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import styles from "./style.module.css";
import CategoryBlock from "./categoryBlock";
import { getBrandPageInfo } from "../../store/MainSlice";
import LoadContent from "../../components/LoadContent";

const Hisense = () => {
  const { currentLanguage, brand } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBanerSliders());
    dispatch(getBestDealProduct());
    dispatch(getBrandPageInfo("hisense"));
  }, []);
  const [productData, setProductData] = useState(null);
  const [cardDataRefrigеrators, setCardDataRefrigеrators] = useState([]);
  const handlerSetThirdSliderData = (cat) => {
    setCardDataRefrigеrators(
      brand?.products_third_slider.filter(
        (prod) => prod?.product?.categories[0].id == cat.id
      )
    );
  };
  const handlerFirstTabData = (cat) => {
    setProductData(brand?.photos?.filter((prod) => prod.category_id == cat.id));
  };
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "hisense", value: "/hisense", active: true }]}
      />
      <BanerSlider
        containerStyle={{ padding: "0" , position : "relative"}}
        bannerSliders={brand?.slider1}
        imgStyle={{ maxHeight: "none" }}
      />
      {!Object.keys(brand || {}).length ? (
        <LoadContent containerClassName={styles.LoadContentGeneralBaner} />
      ) : (
        <div className={styles.InfoAboutPage}>
          <element
            dangerouslySetInnerHTML={{
              __html: brand?.info?.["descr2_" + currentLanguage],
            }}
          ></element>
        </div>
      )}
      <p className={styles.title}>
        {brand?.info?.["subtitle1_" + currentLanguage]}
      </p>
      <div className={styles.promotionCategoryBlock}>
        {brand?.categories1?.map((cat) => (
          <div
            key={cat.id}
            className={
              productData?.find((prod) => prod.category_id == cat.id)
                ? styles.categories + " " + styles.categoriesActive
                : styles.categories
            }
            onClick={() => {
              handlerFirstTabData(cat);
            }}
          >
            <span>{cat["name_" + currentLanguage]}</span>
          </div>
        ))}
      </div>
      <div className={styles.discoverBrands}>
        <div className={styles.discoverBanners}>
          <a
            href={
              productData ? productData?.[0]?.href : brand?.photos?.[4]?.href
            }
          >
            {!Object.keys(brand || {}).length ? (
              <LoadContent
                containerClassName={styles.LoadContentGeneralBaner}
              />
            ) : productData ? (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  productData[0]?.["image_" + currentLanguage]
                }
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.photos?.[4]?.["image_" + currentLanguage]
                }
              />
            )}
          </a>
        </div>
        <div className={styles.discoverBanners}>
          <a
            href={
              productData ? productData?.[1]?.href : brand?.photos?.[5]?.href
            }
          >
            {!Object.keys(brand || {}).length ? (
              <LoadContent
                containerClassName={styles.LoadContentGeneralBaner}
              />
            ) : productData ? (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  productData?.[1]?.["image_" + currentLanguage]
                }
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.photos?.[5]?.["image_" + currentLanguage]
                }
              />
            )}
          </a>
        </div>
        <div className={styles.discoverBanners}>
          <a
            href={
              productData ? productData?.[2]?.href : brand?.photos?.[6]?.href
            }
          >
            {!Object.keys(brand || {}).length ? (
              <LoadContent
                containerClassName={styles.LoadContentGeneralBaner}
              />
            ) : productData ? (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  productData?.[2]?.["image_" + currentLanguage]
                }
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  brand?.photos?.[6]?.["image_" + currentLanguage]
                }
              />
            )}
          </a>
        </div>
      </div>
      <a href={brand?.baners.baner_1_href}>
        {!Object.keys(brand || {}).length ? (
          <LoadContent containerClassName={styles.LoadContentGeneralBaner} />
        ) : (
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              brand?.baners["baner_1_" + currentLanguage]
            }
          />
        )}
      </a>
      <p className={styles.secondTitle}>
        {brand?.info?.["subtitle2_" + currentLanguage]}
      </p>
      <div className={styles.categoryBlockTitle}>
        <a className={styles.categories + " " + styles.categoriesActive}>
          {brand?.categories2?.[0]["name_" + currentLanguage]}
        </a>
      </div>

      <CategoryBlock
        myData={brand?.products_first_slider}
        showMoreBtn={true}
        url={`${
          currentLanguage !== "hy" ? "/" + currentLanguage : ""
        }/brand/hisense/category`}
      />
      <p className={styles.secondTitle + " " + styles.smartphonesTitle}>
        {brand?.info?.["subtitle3_" + currentLanguage]}
      </p>
      <div className={styles.categoryBlockTitle}>
        <a className={styles.categories + " " + styles.categoriesActive}>
          {brand?.categories3?.[0]["name_" + currentLanguage]}
        </a>
      </div>
      <CategoryBlock
        myData={brand?.products_second_slider}
        showMoreBtn={true}
        url={`${
          currentLanguage !== "hy" ? "/" + currentLanguage : ""
        }/brand/hisense/category`}
      />

      <div className={styles.bannersBlck}>
        <div className={styles.bcannerContent}>
          <a className={styles.banner} href={brand?.baners.baner_2_href}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                brand?.baners?.["baner_2_" + currentLanguage]
              }
            />
          </a>
          <a className={styles.banner} href={brand?.baners.baner_3_href}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                brand?.baners?.["baner_3_" + currentLanguage]
              }
            />
          </a>
        </div>
        <div className={styles.bcannerContent}>
          <a className={styles.banner} href={brand?.baners.baner_4_href}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                brand?.baners?.["baner_4_" + currentLanguage]
              }
            />
          </a>
          <a className={styles.banner} href={brand?.baners.baner_5_href}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                brand?.baners?.["baner_5_" + currentLanguage]
              }
            />
          </a>
        </div>
      </div>
      <p className={styles.title}>
        {brand?.info?.["subtitle4_" + currentLanguage]}
      </p>
      <div className={styles.promotionCategoryBlock}>
        {brand?.categories4?.map((cat) => (
          <a
            className={styles.categories}
            onClick={() => handlerSetThirdSliderData(cat)}
          >
            {cat["name_" + currentLanguage]}
          </a>
        ))}
      </div>
      <CategoryBlock
        myData={
          cardDataRefrigеrators.length > 0
            ? cardDataRefrigеrators
            : brand?.products_third_slider?.slice(0, 6)
        }
      />
      <div className={styles.lastInfoBrand}>
        <element
          dangerouslySetInnerHTML={{
            __html: brand?.info?.["descr2_" + currentLanguage],
          }}
        ></element>
      </div>
    </div>
  );
};

export default Hisense;
