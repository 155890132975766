import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import ProductsWithSlide from "../ProductsWithSlide";
import { useSelector } from "react-redux";
import lang from "../../lang/lang";
import styles from "./style.module.css";

export default function RelatedProducts() {
  const [activeCategory, setActiveCategory] = useState();
  const { relatedProducts, currentLanguage } = useSelector(({ main }) => main);

  useEffect(() => {
    setActiveCategory(relatedProducts?.related_categories?.[0]?.id);
  }, [relatedProducts]);
  return (
    <>
      {relatedProducts?.related_categories?.length &&
      Object.keys(relatedProducts?.related_categories_products || {})
        ?.length ? (
        <>
          <div className="wrapper">
            <h3 className={styles.title}>
              {lang[currentLanguage].similar_products}
            </h3>
            {window.innerWidth < 600 ? (
              <div className="scrollRow scrollBar">
                {relatedProducts?.related_categories.map((category, index) => {
                  if (
                    relatedProducts?.related_categories_products[category.id]
                      ?.length
                  )
                    return (
                      <SwiperSlide
                        className={`${styles.categoryItem} ${
                          category.id === activeCategory &&
                          styles.activeCategory
                        }`}
                        key={index}
                        onClick={() => setActiveCategory(category.id)}
                      >
                        <div className={styles.imgBlock}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + category.icon
                            }
                            alt={category["name_" + currentLanguage]}
                          />
                        </div>
                        <span>{category["name_" + currentLanguage]}</span>
                      </SwiperSlide>
                    );
                })}
              </div>
            ) : (
              <Swiper spaceBetween={20} slidesPerView={"auto"}>
                {relatedProducts?.related_categories.map((category, index) => {
                  if (
                    relatedProducts?.related_categories_products[category.id]
                      ?.length
                  )
                    return (
                      <SwiperSlide
                        className={`${styles.categoryItem} ${
                          category.id === activeCategory &&
                          styles.activeCategory
                        }`}
                        key={index}
                        onClick={() => setActiveCategory(category.id)}
                      >
                        <div className={styles.imgBlock}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + category.icon
                            }
                            alt={category["name_" + currentLanguage]}
                          />
                        </div>
                        <span>{category["name_" + currentLanguage]}</span>
                      </SwiperSlide>
                    );
                })}
              </Swiper>
            )}
          </div>
          {relatedProducts?.related_categories_products[activeCategory]
            ?.length ? (
            <ProductsWithSlide
              productsData={relatedProducts?.related_categories_products[
                activeCategory
              ]?.map((item) => ({
                id: item.seller_product_id,
                categories: item.categories,
                promo_xprice: item.promo_price,
                seller_product_sku_id: item.seller_product_sku_id,
                skus: item.skus,
                product: {
                  brand: item.brand,
                  product_name: item.product_name,
                  gallary_images_api: item.gallary_images_api,
                  thumbnail_image_source: item.thumbnail_image_source,
                  gift_images: item.gift_images,
                  sticker: item.sticker,
                  media_ids: item.media_ids,
                  average_price: item.average_price,
                  cashback: item.cashback,
                  product_type: item.product_type,
                },
              }))}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}
