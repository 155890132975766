import React from "react";
import styles from "./style.module.css";
import ProductCard from "../../../components/ProductCard";
import LoadContent from "../../../components/LoadContent/index";
import langData from "../../../lang/data";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CategoryBlock = ({ myData, showMoreBtn, url }) => {
  const { currentLanguage } = useSelector(({ main }) => main);

  return (
    <div>
      {!myData?.length ? (
        <LoadContent containerClassName={styles.loadContent} />
      ) : (
        <>
          <div className={styles.categoryBlock}>
            {myData?.map((product) => (
              <ProductCard flexContainer product={product} />
            ))}
          </div>
          {showMoreBtn && (
            <div>
              <Link to={url} className={styles.moreBtn}>
                {langData.buttons.view_all[currentLanguage]}
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryBlock;
