import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectMain, selectCategory } from "store/selector";
import styles from "./styles.module.css";
import Drawer from "components/Drawer";
import { useSearchParams } from "react-router-dom";
import { DATA } from "../../../../data";
import langData from "lang/data";
import checkBase64 from "helpers/checkBase64";

const CountryOfManufacture = ({
  handleActiveAtributes,
  data,
  onClearData,
  sorting,
  setSorting,
  setManufactures,
  manufactures,
  attribute,
  setAtribute,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectCountry, setSelectCountry] = useState([]);
  const [selectManufactor, setSelectManufactor] = useState([]);
  const [selectSorting, setSelectSorting] = useState([]);
  const { currentLanguage } = useSelector(selectMain);
  const { category } = useSelector(selectCategory);
  const [searchParams, setSearchParams] = useSearchParams();
  let activeBrands = searchParams.has("b")
    ? JSON.parse("[" + searchParams.get("b") + "]")
    : [];
  let activeColors = searchParams.has("c")
    ? JSON.parse("[" + searchParams.get("c") + "]")
    : [];
  const onClick = () => {
    setIsOpen(false);
    onClearData({});
    setSorting(false);
    setManufactures(false);
    setAtribute(false);
  };

  const handleSelectSorting = (item) => {
    // if(selectSorting.includes(item)){
    //   const indexToRemove = selectSorting.indexOf(item)
    //   selectSorting.slice(indexToRemove, 1);
    // }
    // if(selectSorting?.length && !selectSorting?.includes(item)){
    //   setSelectSorting([...selectSorting,item])
    // }

    if (selectSorting?.length && selectSorting?.includes(item)) {
      setSelectSorting(selectSorting?.filter((el) => el !== item));
    } else {
      setSelectSorting([item]);
    }
    searchParams.set("s", item);
  };

  useEffect(() => {
    if (!searchParams.get("s")?.length) {
      setSelectSorting([]);
    }
  }, [searchParams.get("s")]);

  const handleSorting = () => {
    setIsOpen(false);
    setManufactures(false);
    setSorting(false);
    const newSearchParams = {};

    if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
    if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
    if (searchParams.get("b")) newSearchParams["b"] = searchParams.get("b");
    if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
    if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
    if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
    if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
    if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
    if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");

    setSearchParams(new URLSearchParams(newSearchParams));

    handleActiveAtributes(langData?.product?.sorting?.[currentLanguage]);
  };
  const handleSelectCountry = useCallback(
    (item, attributeType) => () => {
      const countryId = `${item.attribute_id}_${item.id}`;

      const otherAttributes = category?.activeAttributes || {};
      const colorsWebactive = otherAttributes?.colors || [];
      const brandsWebactive = otherAttributes?.brands || [];

      const activeAttributesBack = Object.keys(otherAttributes).reduce(
        (acc, key) => {
          if (key !== "colors" && key !== "brands") {
            acc[key] = otherAttributes[key];
          }
          return acc;
        },
        {}
      );

      setSelectCountry((prev) => {
        // Remove all elements with the same attribute_id
        const filtered = prev.filter((el) => {
          const [attributeId] = el.split("_");
          return attributeId !== item.attribute_id;
        });

        // Add new countryId to the end of the array
        return [...filtered, countryId];
      });

      let activeAttributes = searchParams.has("a")
        ? searchParams.get("a").split(",")
        : [];

      searchParams.delete("page");

      if (activeAttributes.includes(countryId)) {
        if (attributeType === "screenSize") {
          activeAttributes = activeAttributes.filter((e) => e === countryId);
        } else {
          activeAttributes = activeAttributes.filter((e) => e !== countryId);
        }
      } else {
        // Remove all elements with the same attribute_id
        activeAttributes = activeAttributes.filter(
          (e) => !e.startsWith(`${item.attribute_id}_`)
        );
        activeAttributes.push(countryId);
      }

      if (
        activeAttributesBack[item.attribute_id] &&
        activeAttributesBack[item.attribute_id].length === 1
      ) {
        for (const key in activeAttributesBack) {
          if (activeAttributesBack.hasOwnProperty(key)) {
            const values = activeAttributesBack[key];

            activeAttributes = activeAttributes.filter(
              (e) => !values?.some((value) => e.startsWith(`${key}_${value}`))
            );
          }
        }
        if (brandsWebactive?.length === 1) {
          activeBrands = activeBrands.filter((e) => e !== brandsWebactive?.[0]);
          if (activeBrands.length) {
            searchParams.set("b", activeBrands);
          } else {
            searchParams.delete("b");
          }
        }

        if (colorsWebactive?.length === 1) {
          activeColors = activeColors.filter((e) => e !== colorsWebactive?.[0]);
        }
      }

      if (activeAttributes.length) {
        searchParams.set("a", activeAttributes.join(","));
      } else {
        searchParams.delete("a");
      }
      searchParams.set("attr", item?.attribute_id);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, category?.activeAttributes]
  );

  useEffect(() => {
    const ids = searchParams?.get("a")?.split(",") || [];

    setSelectCountry(ids);
  }, [searchParams]);

  useEffect(() => {
    const ids = searchParams?.get("b")?.split(",");

    setSelectManufactor(
      ids?.map((id) => {
        return +id;
      })
    );
  }, [searchParams.get("b")]);

  const handleSelectManufactor = (item) => {
    if (selectManufactor?.includes(`${item?.id}`)) {
      const indexToRemove = selectManufactor.indexOf(`${item?.id}`);
      selectManufactor.slice(indexToRemove, 1);
    }
    if (selectManufactor?.length && !selectManufactor?.includes(item?.id)) {
      setSelectManufactor([...selectManufactor, item?.id]);
    } else if (
      selectManufactor?.length &&
      selectManufactor?.includes(item?.id)
    ) {
      setSelectManufactor(selectManufactor?.filter((el) => el !== item?.id));
    } else {
      setSelectManufactor([item?.id]);
    }
  };

  const handleManufactor = () => {
    //  setIsOpen(false);
    // setManufactures(false);
    //  setSorting(false);

    const newSearchParams = {};

    if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
    if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
    if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
    if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
    if (selectManufactor?.length > 0)
      newSearchParams["b"] = selectManufactor.join();
    if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
    if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
    if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
    if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");

    setSearchParams(new URLSearchParams(newSearchParams));
    handleActiveAtributes(langData?.product?.manufactor?.[currentLanguage]);
  };

  const handleItem = () => {
    const newSearchParams = {};

    if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
    if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
    if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
    if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
    if (selectManufactor?.length > 0)
      newSearchParams["b"] = selectManufactor.join();
    if (searchParams.get("mn")) newSearchParams["mn"] = searchParams.get("mn");
    if (searchParams.get("mx")) newSearchParams["mx"] = searchParams.get("mx");
    if (searchParams.get("sp")) newSearchParams["sp"] = searchParams.get("sp");
    if (searchParams.get("id")) newSearchParams["id"] = searchParams.get("id");

    setSearchParams(new URLSearchParams(newSearchParams));
    setIsOpen(false);
    setManufactures(false);
    setSorting(false);
    setAtribute(false);
    handleActiveAtributes(data);
  };

  useEffect(() => {
    if (attribute || sorting || manufactures) {
      setIsOpen(true);
    }
  }, [data?.name_en, attribute, sorting, manufactures]);

  useEffect(() => {
    if (searchParams.get("b")) {
      setSelectManufactor(
        searchParams
          ?.get("b")
          ?.split(",")
          ?.map((el) => +el)
      );
    } else {
      setSelectManufactor([]);
      const newSearchParams = {};

      if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
      if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
      if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
      if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
      if (selectManufactor.length > 0)
        newSearchParams["b"] = selectManufactor.join();
      if (searchParams.get("mn"))
        newSearchParams["mn"] = searchParams.get("mn");
      if (searchParams.get("mx"))
        newSearchParams["mx"] = searchParams.get("mx");
      if (searchParams.get("sp"))
        newSearchParams["sp"] = searchParams.get("sp");
      if (searchParams.get("id"))
        newSearchParams["id"] = searchParams.get("id");

      setSearchParams(new URLSearchParams(newSearchParams));
    }
    if (searchParams.get("a")) {
      setSelectCountry(
        searchParams
          ?.get("a")
          ?.split(",")
          ?.map((el) => el)
      );
    } else {
      setSelectCountry([]);
      const newSearchParams = {};

      if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
      if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
      if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
      if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
      if (selectManufactor.length > 0)
        newSearchParams["b"] = selectManufactor.join();
      if (searchParams.get("mn"))
        newSearchParams["mn"] = searchParams.get("mn");
      if (searchParams.get("mx"))
        newSearchParams["mx"] = searchParams.get("mx");
      if (searchParams.get("sp"))
        newSearchParams["sp"] = searchParams.get("sp");
      if (searchParams.get("id"))
        newSearchParams["id"] = searchParams.get("id");

      setSearchParams(new URLSearchParams(newSearchParams));
    }
    if (searchParams.get("s")) {
      setSelectSorting(
        searchParams
          ?.get("s")
          ?.split(",")
          ?.map((el) => el)
      );
    } else {
      setSelectSorting([]);
      const newSearchParams = {};

      if (searchParams.get("a")) newSearchParams["a"] = searchParams.get("a");
      if (searchParams.get("c")) newSearchParams["c"] = searchParams.get("c");
      if (searchParams.get("s")) newSearchParams["s"] = searchParams.get("s");
      if (searchParams.get("d")) newSearchParams["d"] = searchParams.get("d");
      if (selectManufactor.length > 0)
        newSearchParams["b"] = selectManufactor.join();
      if (searchParams.get("mn"))
        newSearchParams["mn"] = searchParams.get("mn");
      if (searchParams.get("mx"))
        newSearchParams["mx"] = searchParams.get("mx");
      if (searchParams.get("sp"))
        newSearchParams["sp"] = searchParams.get("sp");
      if (searchParams.get("id"))
        newSearchParams["id"] = searchParams.get("id");

      setSearchParams(new URLSearchParams(newSearchParams));
    }
  }, []);
  return (
    <Drawer
      handleItem={
        manufactures ? handleManufactor : sorting ? handleSorting : handleItem
      }
      title={
        manufactures
          ? langData?.product.manufactor?.[currentLanguage]
          : sorting
          ? langData?.product.sorting?.[currentLanguage]
          : data?.["name_" + currentLanguage]
      }
      onClick={onClick}
      isOpen={isOpen}
      height="100dvh"
      filter="1"
      top="0px">
      <div className={styles.country}>
        {sorting
          ? DATA?.map((item) => (
              <div className={styles.countryItem}>
                <p className={styles.checkboxWrapper}>
                  <span onClick={() => handleSelectSorting(item?.value)}>
                    {item?.["label_" + currentLanguage]}
                  </span>
                  <input
                    type="radio"
                    id={item?.value}
                    onChange={() => handleSelectSorting(item?.value)}
                    name="radio-group"
                    checked={selectSorting?.includes(item?.value)}
                  />
                  <label
                    for={item?.value}
                    onClick={() => handleSelectSorting(item?.value)}
                  />
                </p>
              </div>
            ))
          : manufactures
          ? category?.brandList?.map((item) => (
              <div className={styles.countryItem}>
                <p className={styles.checkboxWrapper}>
                  <div className={styles.brandImg}>
                    <img
                      onClick={() => handleSelectManufactor(item)}
                      src={
                        item?.logo
                          ? checkBase64(item?.logo)
                            ? "data:image/png;base64," + item.logo
                            : process.env.REACT_APP_STORAGE_URL + item.logo
                          : "https://vlv.am/public/frontend/default/img/brand_image.png"
                      }
                      alt="brand"
                    />
                  </div>
                  <input
                    type="checkbox"
                    id={item?.id}
                    name="radio-group"
                    checked={selectManufactor?.includes(item?.id)}
                    onChange={() => handleSelectManufactor(item)}
                  />
                  <label
                    for={item?.id}
                    onClick={() => handleSelectManufactor(item)}
                  />
                </p>
              </div>
            ))
          : data?.values?.map((item) => (
              <div className={styles.countryItem}>
                <p className={styles.checkboxWrapper}>
                  <span onClick={() => handleSelectCountry(item)}>
                    {item?.["value_" + currentLanguage]}
                  </span>
                  <input
                    type="checkbox"
                    id={item?.value_en}
                    name="radio-group"
                    onChange={() => handleSelectCountry(item)}
                    checked={selectCountry?.includes(
                      `${item?.attribute_id}_${item?.id}`
                    )}
                  />
                  <label
                    for={item?.value_en}
                    onClick={() => handleSelectCountry(item)}
                  />
                </p>
              </div>
            ))}
      </div>
    </Drawer>
  );
};

export default CountryOfManufacture;
