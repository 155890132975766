import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../LoadContent";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderNextArrowSvg from "../../assets/SVG/SliderNextArrowSvg";
import { Autoplay } from "swiper/modules";
import langData from "../../lang/data";
import { getBrand } from "../../store/MainSlice";
import { Link } from "react-router-dom";
import useWindowSize from "components/useWindowSize";

const Brand = ({ innerBrands }) => {
  const [showBtns, setShowBtns] = useState(false);
  const [brandsState, setBrandsState] = useState(null);
  const { brands, currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    let array1 = [];
    let array2 = [];
    if (innerBrands?.length) {
      [...innerBrands, ...innerBrands]?.forEach((brand, index) => {
        if (array1.length >= 2) {
          array2.push(array1);
          array1 = [brand];
          if (index + 1 === innerBrands?.length) {
            array2.push(array1);
          }
        } else {
          array1.push(brand);
        }
      });
      setBrandsState(array2);
    } else {
      if (!brands?.length) {
        dispatch(getBrand());
      }

      brands?.forEach((brand, index) => {
        if (array1.length >= 2) {
          array2.push(array1);
          array1 = [brand];
          if (index + 1 === brands?.length) {
            array2.push(array1);
          }
        } else {
          array1.push(brand);
        }
      });
      setBrandsState(array2);
    }
  }, [brands, innerBrands]);

  const swiperRef = useRef();

  return (
    <div className="wrapper">
      <div className={styles.titleBlock}>
        <h3 className="title">{langData.titles.Brand[currentLanguage]}</h3>
        <Link
          to={`${
            currentLanguage !== "hy" ? "/" + currentLanguage : ""
          }/brandSeeAll`}
          className={styles.moreBtn}>
          {langData.buttons.view_all[currentLanguage]}
        </Link>
      </div>

      <div
        style={{
          width: "100%",
          alignItems: "center",
          position: "relative",
          marginBottom: "20px",
        }}
        onMouseMove={() => windowWidth > 768 && setShowBtns(true)}
        onMouseOut={() => windowWidth > 768 && setShowBtns(false)}>
        {windowWidth < 600 ? (
          <div className="scrollRow">
            {!brandsState?.length
              ? Array.from({ length: 8 }).map((_, index) => (
                  <SwiperSlide key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "20px",
                      }}>
                      <LoadContent containerClassName={styles.loadContent} />
                      <LoadContent containerClassName={styles.loadContent} />
                    </div>
                  </SwiperSlide>
                ))
              : brandsState.map((brandsArray, index) => (
                  <SwiperSlide
                    style={{
                      width: "min-content",
                      minWidth: "100px",
                    }}
                    key={index}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "20px",
                      }}>
                      <Link
                        className={styles.brandItem}
                        to={`${
                          currentLanguage !== "hy" ? "/" + currentLanguage : ""
                        }/brand/${brandsArray[0].slug}/category`}>
                        <img
                          className={styles.brandItemImg}
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            brandsArray[0].logo
                          }
                          alt={brandsArray[0].name}
                        />
                      </Link>

                      {brandsArray[1] && (
                        <Link
                          className={styles.brandItem}
                          to={`${
                            currentLanguage !== "hy"
                              ? "/" + currentLanguage
                              : ""
                          }/brand/${brandsArray[1].slug}/category`}>
                          <img
                            className={styles.brandItemImg}
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              brandsArray[1].logo
                            }
                            alt={brandsArray[1].name}
                          />
                        </Link>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
          </div>
        ) : (
          <>
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                style={{
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slidePrev()}>
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                style={{
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slideNext()}>
                <SliderNextArrowSvg />
              </div>
            </>

            <Swiper
              modules={[Autoplay]}
              loop
              autoplay
              spaceBetween={24}
              // slidesPerView={5}
              breakpoints={{
                1440: {
                  slidesPerView: 5,
                },
                1024: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 3,
                },
                425: {
                  slidesPerView: 2,
                },
              }}
              className={styles.Swiper}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}>
              {!brandsState?.length
                ? Array.from({ length: 8 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "20px",
                        }}>
                        <LoadContent containerClassName={styles.loadContent} />
                        <LoadContent containerClassName={styles.loadContent} />
                      </div>
                    </SwiperSlide>
                  ))
                : brandsState?.map((brandsArray, index) => (
                    <SwiperSlide
                      // style={{
                      //   minWidth: "100px",
                      //   display: "flex",
                      // }}
                      key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "20px",
                        }}>
                        <Link
                          className={styles.brandItem}
                          to={`${
                            currentLanguage !== "hy"
                              ? "/" + currentLanguage
                              : ""
                          }/brand/${brandsArray[0].slug}/category`}>
                          <img
                            className={styles.brandItemImg}
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              brandsArray[0].logo
                            }
                            alt={brandsArray[0].name}
                          />
                        </Link>

                        {brandsArray[1] && (
                          <Link
                            className={styles.brandItem}
                            to={`${
                              currentLanguage !== "hy"
                                ? "/" + currentLanguage
                                : ""
                            }/brand/${brandsArray[1].slug}/category`}>
                            <img
                              className={styles.brandItemImg}
                              src={
                                process.env.REACT_APP_STORAGE_URL +
                                brandsArray[1].logo
                              }
                              alt={brandsArray[1].name}
                            />
                          </Link>
                        )}
                      </div>
                    </SwiperSlide>
                  ))}
            </Swiper>
          </>
        )}
      </div>
    </div>
  );
};
export default Brand;
