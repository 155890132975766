import React, { useEffect, useRef, useState } from "react";
import LoadContent from "components/LoadContent";
import { useSelector } from "react-redux";
import { selectCategory, selectMain, selectSearch } from "store/selector";
import Colors from "./components/colors/Colors";
import CountryOfManufacture from "./components/countryManufactur/CountryOfManufacture";
import Cost from "./components/cost/Cost";
import CloseIcon from "assets/SVG/CloseSvg";
import Filter from "./components/filter";
import langData from "lang/data";
import { ReactComponent as FilterIcon } from "assets/SVG/filterIcon.svg";
import { useSearchParams } from "react-router-dom";

const Attributes = ({ styles, setCurrentPage, category, loading }) => {
  const { currentLanguage } = useSelector(selectMain);

  const [cost, setCost] = useState(false);
  const [color, setColor] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [manufactures, setManufactures] = useState(false);
  const [attribute, setAtribute] = useState(false);
  const [activeAttribute, setActiveAtribute] = useState([]);
  const [openModal, setOpenModal] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const filteredAttributes = category?.initial_attributes?.filter((el) =>
    el?.values?.some((elem) => {
      return `${elem?.attribute_id}_${elem?.id}`;
    })
  );

  const handleActiveAtributes = (item) => {
    if (!activeAttribute) {
      return null;
    }
    const objectExists = activeAttribute?.some((obj) => obj.id === item.id);

    if (typeof item !== "string" && !objectExists) {
      setActiveAtribute((prev) => [...prev, item]);
    } else if (
      item === langData?.product?.color?.[currentLanguage] ||
      item === langData?.product?.manufactor?.[currentLanguage] ||
      item === langData?.product?.cost?.[currentLanguage] ||
      item === langData?.product?.sorting?.[currentLanguage]
    ) {
      setActiveAtribute((prev) => [...prev, item]);
    }
  };

  const allSearchParams = {};
  for (const [key, value] of searchParams.entries()) {
    if (value !== null && value !== "") {
      allSearchParams[key] = value;
    }
  }
  useEffect(() => {
    const updatedSearchParams = new URLSearchParams();

    for (const [key, value] of searchParams.entries()) {
      if (value !== null && value !== "") {
        updatedSearchParams.set(key, value);
      }
    }

    setSearchParams(updatedSearchParams);
  }, [searchParams]);

  const handleDeleteAttribute = (item) => {
    if (langData?.product?.color?.[currentLanguage] === item) {
      setActiveAtribute(activeAttribute.filter((el) => item !== el));
      searchParams.delete("c");
      setSearchParams(searchParams);
    }

    if (langData?.product?.manufactor?.[currentLanguage] === item) {
      setActiveAtribute(activeAttribute.filter((el) => item !== el));
      searchParams.delete("b");
      setSearchParams(searchParams);
    }

    if (langData?.product?.sorting?.[currentLanguage] === item) {
      setActiveAtribute(activeAttribute.filter((el) => item !== el));
      searchParams.delete("s");
      setSearchParams(searchParams);
    }

    if (langData?.product?.cost?.[currentLanguage] === item) {
      setActiveAtribute(activeAttribute.filter((el) => item !== el));
      searchParams.delete("mn");
      searchParams.delete("mx");
      searchParams.delete("d");
      setSearchParams(searchParams);
    }

    if (typeof item === "object") {
      setActiveAtribute(
        activeAttribute.filter(
          (el) =>
            item?.["name_" + currentLanguage] !==
            el?.["name_" + currentLanguage]
        )
      );
      const filteredB = allSearchParams?.a?.split(",").filter((bItem) => {
        const [attributeId, id] = bItem.split("_");
        const matchingItem = item?.values.find(
          (aItem) =>
            aItem.attribute_id === parseInt(attributeId) &&
            aItem.id === parseInt(id)
        );
        return !matchingItem;
      });
      const result = filteredB?.map((item) => item.toString()) ?? [];
      setSearchParams({
        ...allSearchParams,
        a: result?.join(),
      });
    }
  };
  function getObjectById(id) {
    return category?.new_attributes?.find((attribute) => attribute?.id === id);
  }
  const handleModal = (item) => {
    if (typeof item !== "string") {
      setAtribute(true);

      const result = getObjectById(item.id);

      setOpenModal(result);
    }
    if (item === langData?.product?.color?.[currentLanguage]) {
      setColor(true);
    }
    if (item === langData?.product?.cost?.[currentLanguage]) {
      setCost(true);
    }
    if (item === langData?.product?.manufactor?.[currentLanguage]) {
      setManufactures(true);
    }
    if (item === langData?.product?.sorting?.[currentLanguage]) {
      setSorting(true);
    }
  };

  useEffect(() => {
    if (
      searchParams.get("c") &&
      !activeAttribute?.includes(langData?.product?.color?.[currentLanguage])
    ) {
      setActiveAtribute((prev) => {
        if (!Array.isArray(prev)) {
          return [langData?.product?.color?.[currentLanguage]];
        }
        return [...prev, langData?.product?.color?.[currentLanguage]];
      });
    }

    if (
      searchParams.get("b") &&
      !activeAttribute?.includes(
        langData?.product?.manufactor?.[currentLanguage]
      )
    ) {
      setActiveAtribute((prev) => {
        if (!Array.isArray(prev)) {
          return [langData?.product?.manufactor?.[currentLanguage]];
        }
        return [...prev, langData?.product?.manufactor?.[currentLanguage]];
      });
    }

    if (
      searchParams.get("s") &&
      !activeAttribute?.includes(langData?.product?.sorting?.[currentLanguage])
    ) {
      setActiveAtribute((prev) => {
        if (!Array.isArray(prev)) {
          return [langData?.product?.sorting?.[currentLanguage]];
        }
        return [...prev, langData?.product?.sorting?.[currentLanguage]];
      });
    }

    if (
      (searchParams.get("mn") || searchParams.get("mx")) &&
      !activeAttribute?.includes(langData?.product?.cost?.[currentLanguage])
    ) {
      setActiveAtribute((prev) => {
        if (!Array.isArray(prev)) {
          return [langData?.product?.cost?.[currentLanguage]];
        }
        return [...prev, langData?.product?.cost?.[currentLanguage]];
      });
    }
  }, [searchParams, currentLanguage, activeAttribute]);

  useEffect(() => {
    if (!searchParams.get("b")) {
      setActiveAtribute(
        activeAttribute?.filter(
          (el) => el !== langData?.product?.manufactor?.[currentLanguage]
        )
      );
    }
    if (!searchParams.get("c")) {
      setActiveAtribute(
        activeAttribute?.filter(
          (el) => el !== langData?.product?.color?.[currentLanguage]
        )
      );
    }
    if (!searchParams.get("s")) {
      setActiveAtribute(
        activeAttribute?.filter(
          (el) => el !== langData?.product?.sorting?.[currentLanguage]
        )
      );
    }
    if (!searchParams.get("a")) {
      setActiveAtribute(activeAttribute?.filter((el) => !Object.keys(el)));
    }

    if (searchParams.get("a")) {
      const atributUrl = searchParams.get("a")?.split(",");
      const newfilter = filteredAttributes?.filter((el) =>
        el?.values?.some((elem) => {
          return atributUrl?.includes(`${elem?.attribute_id}_${elem?.id}`);
        })
      );

      setActiveAtribute(newfilter);
    }
  }, [searchParams]);

  useEffect(() => {
    const atributUrl = searchParams.get("a")?.split(",");
    const newfilter = filteredAttributes?.filter((el) =>
      el?.values?.some((elem) => {
        return atributUrl?.includes(`${elem?.attribute_id}_${elem?.id}`);
      })
    );

    setActiveAtribute(newfilter);
  }, [category]);

  return (
    <>
      {loading ? (
        <LoadContent containerClassName={styles.categoryAtributeLoad} />
      ) : (
        <>
          <div className={styles.categoryAtribute}>
            <div className={styles.categoryAtributeFix}>
              <FilterIcon onClick={() => setOpenModal({ name_en: "filter" })} />
            </div>
            <div className={styles.categoryContainer}>
              <span
                onClick={() => {
                  setSorting(true);
                }}>
                {langData?.product?.sorting?.[currentLanguage]}
              </span>
              <span onClick={() => setCost(true)}>
                {langData?.product?.cost?.[currentLanguage]}
              </span>
              {!!category?.brandList?.length && (
                <span onClick={() => setManufactures(true)}>
                  {langData?.product?.manufactor?.[currentLanguage]}
                </span>
              )}
              {!!category?.color?.length && (
                <span onClick={() => setColor(true)}>
                  {langData?.product?.color?.[currentLanguage]}
                </span>
              )}
              {category?.attributes?.map(
                (item) =>
                  !!item?.show_in_mobile && (
                    <span
                      key={item.id}
                      onClick={() => {
                        setOpenModal(item);
                        setAtribute(true);
                      }}>
                      {item?.["name_" + currentLanguage]}
                    </span>
                  )
              )}
            </div>
          </div>

          {!!activeAttribute?.length && (
            <div className={styles.categoryAtributeActive}>
              {[...new Set(activeAttribute)]?.map((item, index) => (
                <span
                  onClick={() => {
                    if (item?.styles && item?.styles === 1) {
                      setOpenModal({
                        name_en: "filter",
                      });
                    } else {
                      handleModal(item);
                    }
                  }}
                  key={index}>
                  {typeof item === "object"
                    ? item?.["name_" + currentLanguage]
                    : item}
                  <CloseIcon
                    onClick={(event) => {
                      // here
                      event.stopPropagation();
                      handleDeleteAttribute(item);
                    }}
                  />
                </span>
              ))}
            </div>
          )}
          {searchParams.get("st") && (
            <div className={styles.categoryAtributeActive}>
              <span>
                {searchParams.get("st")}
                <CloseIcon
                  onClick={(event) => {
                    searchParams.delete("st");
                    setSearchParams(searchParams);
                  }}
                />
              </span>
            </div>
          )}
        </>
      )}

      <Filter
        data={openModal}
        onClearData={setOpenModal}
        handleDeleteAttribute={handleDeleteAttribute}
      />
      <Colors
        data={openModal}
        handleActiveAtributes={handleActiveAtributes}
        color={color}
        setColor={setColor}
        onClearData={setOpenModal}
      />
      <CountryOfManufacture
        handleActiveAtributes={handleActiveAtributes}
        sorting={sorting}
        setSorting={setSorting}
        attribute={attribute}
        setAtribute={setAtribute}
        manufactures={manufactures}
        setManufactures={setManufactures}
        data={openModal}
        onClearData={setOpenModal}
      />
      {cost && (
        <Cost
          setCurrentPage={setCurrentPage}
          handleActiveAtributes={handleActiveAtributes}
          data={cost}
          setCost={setCost}
          onClearData={setOpenModal}
        />
      )}
    </>
  );
};

export default Attributes;
