import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContactInfo } from "../../store/contactPage/contactPageFetch";
import FromToPage from "../../components/FromToPage";
import AddresItem from "./components/addresItem";
import Map from "../../components/Map";
import LoadContent from "../../components/LoadContent";
import styles from "./style.module.css";
import langData from "../../lang/data";
import lang from "lang/lang";
import { Link } from "react-router-dom";

export default function Contacts() {
  const dispatch = useDispatch();
  const { isLoading, contactPage } = useSelector(
    (state) => state.ContactPageSlice
  );
  const { currentLanguage } = useSelector(({ main }) => main);

  const defaultCenter = { lat: 40.150011, lng: 44.495715 };
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    dispatch(getContactInfo()).catch((error) => {
      console.error("Failed to fetch contact info:", error);
    });
  }, [dispatch]);

  useEffect(() => {
    if (contactPage?.addresses?.length > 0) {
      const center = {
        lat: Number(contactPage.addresses[0]?.location?.lat),
        lng: Number(contactPage.addresses[0]?.location?.lng),
      };
      setMapCenter(center);
    }
  }, [contactPage]);

  const addresses = useMemo(() => {
    if (search !== "" && contactPage?.addresses?.length) {
      const normalizedSearch = search.toLowerCase();

      return contactPage.addresses.filter((item) => {
        if (!item) return false; // Skip undefined items

        const addressEn = item?.address_en?.toLowerCase() || "";
        const addressHy = item?.address_hy?.toLowerCase() || "";
        const addressRu = item?.address_ru?.toLowerCase() || "";

        return (
          addressEn.includes(normalizedSearch) ||
          addressHy.includes(normalizedSearch) ||
          addressRu.includes(normalizedSearch)
        );
      });
    }
    return contactPage?.addresses || [];
  }, [search, contactPage]);

  const handleAddressClick = useCallback((lat, lng, index) => {
    if (lat && lng) {
      setMapCenter({ lat, lng });
      setSelectedIndex(index);
    }
  }, []);

  const handleMarkerClick = (address) => {
    const lat = parseFloat(address?.location?.lat) || defaultCenter.lat;
    const lng = parseFloat(address?.location?.lng) || defaultCenter.lng;

    setSelected({ lat, lng });
  };

  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.header.menu.Contacts[currentLanguage],
            value: "/contacts",
            active: true,
          },
        ]}
      />

      {!isLoading ? (
        <>
          <h1 className={styles.contact_title}>
            {langData.header.menu.Contacts[currentLanguage]}
          </h1>
          <div className={styles.contacts_icons}>
            {Object.entries(
              contactPage?.contact?.["banners_" + currentLanguage] || {}
            ).map(([key, value], index) => (
              <Link
                to={
                  key === "tel" || key === "tel2"
                    ? `tel:${value.title}`
                    : key === "email"
                    ? `mailto:${value.title}`
                    : value.title
                }
                key={index}
                className={styles.cont_block}>
                <div className={styles.round_cont_svg}>
                  <img
                    className="ls-is-cached lazyloaded"
                    alt="img"
                    src={
                      value?.icon
                        ? process.env.REACT_APP_STORAGE_URL + value.icon
                        : process.env.REACT_APP_STORAGE_URL +
                          contactPage?.contact?.["banners_" + currentLanguage]
                            ?.tel?.icon
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = require("../../assets/defaultImg.png");
                    }}
                  />
                </div>
                {value?.title}
              </Link>
            ))}
            <div className={styles.cont_blokes}>
              <span className={styles.cont_titles}>
                {contactPage?.contact?.["soc_title_" + currentLanguage]}
              </span>
              <div className={styles.social_media_f}>
                {Object.entries(
                  contactPage?.contact?.["socs_" + currentLanguage] || {}
                ).map(([key, value], index) => (
                  <Link
                    to={
                      key === "viber"
                        ? `viber://add?number=${value?.link}`
                        : key === "whatsapp"
                        ? `whatsapp://add?number=${value?.link}`
                        : value?.link
                    }
                    target={"_blank"}
                    key={index}
                    className={styles.social_svgs}>
                    <img
                      alt="img"
                      src={
                        value?.icon
                          ? process.env.REACT_APP_STORAGE_URL + value.icon
                          : process.env.REACT_APP_STORAGE_URL +
                            contactPage?.contact?.["socs_" + currentLanguage]
                              ?.tel?.icon
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = require("../../assets/defaultImg.png");
                      }}
                    />
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadContent containerClassName={styles.loadContent_icons} />
      )}

      <div className={styles.contact_second_title}>
        {lang[currentLanguage].shops}
      </div>
      <div className={styles.contact_maps_block}>
        {!isLoading ? (
          <>
            <div className={styles.contact_form}>
              <div
                className={styles.contact_compare_form}
                id="search">
                <input
                  value={search}
                  onChange={({ target }) => {
                    setSearch(target.value);
                    setSelectedIndex(null);
                  }}
                  type="text"
                  className={styles.head_search}
                  placeholder={lang[currentLanguage].address_shop_address}
                />
                <button
                  className={styles.search_btn_}
                  disabled={true}>
                  {langData.buttons.search[currentLanguage]}
                </button>
              </div>
              <div className={styles.contact_addres_list}>
                {addresses.length
                  ? addresses.map((address, index) => (
                      <AddresItem
                        key={index}
                        handleAddress={() => {
                          handleAddressClick(
                            address.location.lat,
                            address.location.lng,
                            index
                          );
                          handleMarkerClick(address);
                        }}
                        item={address}
                      />
                    ))
                  : null}
              </div>
            </div>
          </>
        ) : (
          <LoadContent containerClassName={styles.loadContent_form} />
        )}
        <div className={styles.contact_form_maps}>
          <Map
            addresses={addresses}
            mapCenter={mapCenter}
            selectedIndex={selectedIndex}
            selected={selected}
            setSelected={setSelected}
            handleMarkerClick={handleMarkerClick}
            setMapCenter={setMapCenter}
          />
        </div>
      </div>
    </div>
  );
}
